import type { LngLatBounds } from 'mapbox-gl';
import React, { useMemo } from 'react';
import Modal from 'react-modal';
import { useParams, useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { ProjectConfig } from '@jan6evidence/settings';
import { Evidence } from '@jan6evidence/types';

import LoadState from '../../types/LoadState';
import EvidenceDetail from '../EvidenceDetail';
import { HighlightContext } from '../shared/contexts';
import { debounce } from '../shared/util';
import {
  EvidenceResultsWrapper,
  EvidenceTableWrapper,
  EvidenceCount,
} from '../table-styled-components';

import EvidenceMapbox, { MapViewport } from './EvidenceMapbox';
import EvidenceTable from './EvidenceTable';
import EvidenceTimeline from './EvidenceTimeline';

Modal.setAppElement('#root');

export { EvidenceTable, EvidenceTimeline, EvidenceMapbox };

type StartEndTimes = [Date, Date] | null;

type Props = {
  evidence: Evidence[];
  timedEvidence: Evidence[];
  projectConfig: ProjectConfig;
  startEndTimes?: StartEndTimes;
  setStartEndTimes?: (se: StartEndTimes) => void;
  hideExplorerLink?: boolean;
  rootPath: string;
  getDetailPath: (id: string) => string;
  setBounds?: (bounds: LngLatBounds | null) => void;
  setExportView: (show: boolean) => void;
  collectionsFC?: (props: { id?: string; location?: string }) => React.ReactNode;
  loadState: LoadState;
  loadError?: string;
};

const EvidenceViewer = ({
  evidence,
  timedEvidence,
  projectConfig,
  startEndTimes,
  setStartEndTimes,
  rootPath,
  getDetailPath,
  // setExportView,
  setBounds,
  collectionsFC,
  loadState,
  loadError,
}: Props) => {
  const [highlight, setHighlight] = React.useState<string>('');
  const { detailId } = useParams<{ detailId?: string }>();
  const history = useHistory();
  const location = useLocation();

  const closeModal = React.useCallback(() => {
    history.push({ pathname: rootPath, search: location.search });
  }, [history, location]);

  const debouncedHighlight = React.useCallback(debounce(setHighlight, 16), []);

  let prevEvidence: Evidence | undefined;
  let nextEvidence: Evidence | undefined;
  const detailEvidence = detailId
    ? evidence.find((r, i) => {
        if (r._id === detailId) {
          prevEvidence = evidence[i - 1];
          nextEvidence = evidence[i + 1];
          return true;
        }
        return false;
      })!
    : { _id: '' };

  const status = useMemo(() => {
    if (loadState === LoadState.INIT || loadState === LoadState.LOADING) {
      return 'loading...';
    }
    if (loadState === LoadState.ERROR) {
      return loadError || 'Error loading data';
    }
    // LoadState.LOADED
    return `${evidence.length} bit${evidence.length === 1 ? '' : 's'} of evidence`;
  }, [loadState, loadError, evidence.length]);

  return (
    <EvidenceResultsWrapper>
      <EvidenceMapbox
        evidence={evidence}
        onHover={debouncedHighlight}
        highlight={highlight}
        onChangeBounds={setBounds}
        defaultMapViewport={projectConfig.defaultMapViewport}
      />
      <EvidenceTimeline
        highlight={highlight}
        setHighlight={debouncedHighlight}
        startEndTimes={startEndTimes}
        onChangeTimes={setStartEndTimes}
        // We re-filter here, ignoring time time filter, so that we still show
        // evidence on the timeline that isn't in the selected time range
        evidence={timedEvidence}
        tickIntervalMsec={projectConfig.timeline.tickIntervalHours * 60 * 60 * 1000}
        domainStart={projectConfig.timeline.start.getTime()}
        domainEnd={projectConfig.timeline.end.getTime()}
        timeFormatterConfig={projectConfig.timeFormat}
      />
      <EvidenceCount>
        {status}
        {/*
        <a
          href="export"
          onClick={(e) => {
            e.preventDefault();
            setExportView(true);
          }}
        >
          Export
        </a>
        */}
      </EvidenceCount>
      <EvidenceTableWrapper>
        <EvidenceTable
          results={evidence}
          highlight={highlight}
          onHover={debouncedHighlight}
          getDetailPath={getDetailPath}
          timeFormatterConfig={projectConfig.timeFormat}
        />
      </EvidenceTableWrapper>
      <EvidenceDetail
        collectionsFC={collectionsFC}
        detailId={detailId}
        evidence={detailEvidence}
        onClose={closeModal}
        prevPath={prevEvidence ? getDetailPath(prevEvidence?._id) : ''}
        nextPath={nextEvidence ? getDetailPath(nextEvidence?._id) : ''}
        timeFormatterConfig={projectConfig.timeFormat}
      />
    </EvidenceResultsWrapper>
  );
};

export default EvidenceViewer;
