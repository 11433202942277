import evidenceData from '@jan6evidence/data/evidence-snapshot.json';

import type { Evidence } from '@jan6evidence/types';

import { makeColor, toLatLng, toTimelinePercent } from './data-helpers';

// Dates are serailized as strings, so we need to manually deserialize
// Would be nice to move this deserializaiton to ../data/index.ts but we tried
// and ran into compile errors, so we're doing it here for now.
const toDate = (str: string | undefined): Date | undefined => {
  if (!str) {
    return undefined;
  }
  try {
    return new Date(str);
  } catch {
    return undefined;
  }
};

const getStaticEvidence = (timelineDomainStart: number, timelineDomainEnd: number): Evidence[] =>
  evidenceData.map(
    (item: any): Evidence => {
      const { itemId, versionedId, ...otherFields } = item;
      const realTimeStart = toDate(item.realTimeStart);
      const realTimeEnd = toDate(item.realTimeEnd);
      const timelineStartPercent = toTimelinePercent(
        realTimeStart,
        timelineDomainStart,
        timelineDomainEnd
      );
      return {
        ...otherFields,
        _id: itemId,
        realTimeStart,
        realTimeEnd,
        // TODO: move this to the data generation step?
        latLng: item.location ? toLatLng(item.location, itemId) : undefined,
        timelineStartPercent,
        color: makeColor(timelineStartPercent),
      } as Evidence;
    }
  );

// eslint-disable-next-line import/prefer-default-export
export { getStaticEvidence };
