import React from 'react';

import { PROJECT_CONFIG } from '@jan6evidence/settings';

import AboutPage from './AboutPage';
import { PageHeaderAndNav } from './PageHeaderAndNav';
import { AppWrapper } from './table-styled-components';

function StaticAboutPage() {
  return (
    <AppWrapper>
      <PageHeaderAndNav projectConfig={PROJECT_CONFIG} />
      <AboutPage />
    </AppWrapper>
  );
}

export default StaticAboutPage;
