import React from 'react';
import { Link } from 'react-router-dom';
import { Follow } from 'react-twitter-widgets';

import styled from 'styled-components';

import { PATHS } from '@jan6evidence/routes';

import { ProjectConfig } from '@jan6evidence/settings';

import { colors, widths, padding } from './shared/constants';

export const PageHeaderDiv = styled.div`
  padding: ${padding.sm}px ${padding.lg}px;
  display: flex;
  flex-direction: row;
  align-items: center;

  background: ${colors.titleBg};

  .sitename {
    margin: 0;
    display: inline-block;
    font-size: 1.8rem;
    margin-right: ${padding.xl}px;
    position: relative;
    white-space: nowrap;

    a {
      color: #000;
    }

    a: hover {
      text-decoration: none;
    }
  }

  .sitename .beta {
    position: absolute;
    top: 0rem;
    right: -1rem;
    background: #003399;
    color: #ffffff;
    font-size: 0.6rem;
    font-weight: bold;
    padding: 0px 3px;
    transform: rotate(10deg);
  }
`;

const NavList = styled.ul`
  margin: 0px;
  padding: 0px;
  white-space: nowrap;

  li {
    display: inline-block;
    margin-right: ${padding.lg}px;
    margin-bottom: 0px;
  }

  a {
    color: ${colors.linkHover};
  }
`;

const UpdateDiv = styled.div`
  display: inline-block;
  margin-left: auto;
  font-size: 0.8rem;
  text-align: center;
  white-space: nowrap;
  color: ${colors.linkHover};
`;

const mostRecentUpdateStr = () => {
  return 'FIXME ';
};

const navLinkOrText = ({ to, text, isLink }: { to: string; text: string; isLink: boolean }) => {
  if (isLink) {
    return (
      <li>
        <Link to={to}>{text}</Link>
      </li>
    );
  } else {
    return <li>{text}</li>;
  }
};

const Jan6NavList = () => (
  <NavList>
    {navLinkOrText({ to: PATHS.evidence, text: 'Explorer', isLink: true })}
    {navLinkOrText({ to: PATHS.suspects, text: 'Persons of Interest', isLink: true })}
    {navLinkOrText({ to: PATHS.mediaItems, text: 'Media', isLink: true })}
    {navLinkOrText({ to: PATHS.about, text: 'About', isLink: true })}
    {navLinkOrText({ to: PATHS.resources, text: 'Resources', isLink: true })}
  </NavList>
);

const Jan6Updates = () => (
  <UpdateDiv>
    {/*
    // uncomment when mostRecentUpdateStr is working
   (
    <span>
      Updated {mostRecentUpdateStr()} <br />
    </span>
   )
  */}
    <span>
      For updates and feedback: <br />
    </span>
    <a
      href="https://twitter.com/jan6evidence"
      className="twitter-follow-button"
      data-show-count="false"
    >
      Follow @jan6evidence
    </a>
    <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8" />
  </UpdateDiv>
);

export const PageHeaderAndNav = ({ projectConfig }: { projectConfig: ProjectConfig }) => {
  return (
    <PageHeaderDiv>
      <div className="sitename">
        {projectConfig.projectId === 'jan6' ? <div className="beta">VERY BETA</div> : null}
        <Link to="/">{projectConfig.explorerTitle}</Link>
      </div>
      {projectConfig.projectId === 'jan6' ? <Jan6NavList /> : null}
      {projectConfig.projectId === 'jan6' ? <Jan6Updates /> : null}
    </PageHeaderDiv>
  );
};

export default PageHeaderAndNav;
