export type SuspectTag = {
  type: string; // should be 'group' | 'equipment' | 'behavior' but running into problems...
  name: string;
};

export type Suspect = {
  _id: string;
  primaryHashtag?: string; // can be missing for arrested individuals who were not previously hashtagged
  compositeURL?: string;
  filesUrl?: string;
  alternateHashtags: Array<string>;
  fbiIdentifier?: string;
  isMpdSuspect?: boolean;
  idStatus?: string;
  caseStatus?: string;
  profileLeads: Array<string>;
  actionsSummary?: string;
  appearanceSummary?: string;
  associates: Array<string>; // tags of other individuals
  otherTags: Array<SuspectTag>;

  // The following should only be populated if there is a charging document
  chargingDocUrl?: string;
  realName?: string; // Only if arrested or charged.  No DOXXING!
  arrestDate?: Date;
  arrestState?: string; // Only if arrested or charged.  No DOXXING!
  caseIdentifier?: string;
  militaryBranchAffiliation?: string;
  isLawEnforcement?: boolean;
};

const toDate = (str: string | undefined): Date | undefined => {
  if (!str) {
    return undefined;
  }
  try {
    return new Date(str);
  } catch {
    return undefined;
  }
};

export const getSuspectsFromRawData = (items: any[]): Suspect[] =>
  items.map((item) => {
    const arrestDate = toDate(item.arrestDate);
    return {
      ...item,
      arrestDate,
    } as Suspect;
  });
