import type { LngLatBounds } from 'mapbox-gl';
import React from 'react';
import styled from 'styled-components';

import { PATHS } from '@jan6evidence/routes';
import { ProjectConfig, PROJECT_CONFIG } from '@jan6evidence/settings';
import type { Evidence } from '@jan6evidence/types';

import type LoadState from '../types/LoadState';
import type { TagFilters, Toggles } from '../types/TagFilters';

import EvidenceViewer from './EvidenceMain';
import EvidenceExportView from './EvidenceMain/EvidenceExportView';
import MobileOverlay from './EvidenceMain/EvidenceMobileOverlay';
import FilterBox from './FilterBox';
import filterEvidence from './FilterBox/filterEvidence';
import FixedLayout from './FixedLayout';
import PageHeaderAndNav from './PageHeaderAndNav';
import { usePageTitle, useQueryState } from './shared/hooks';

const PageContentWrapper = styled.div`
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
`;

const toArray = (value: string | string[] | null | undefined): string[] => {
  if (Array.isArray(value)) {
    return value;
  }
  if (value == null) {
    return [];
  }
  return [value];
};

interface EvidenceAppProps {
  evidence: Evidence[];
  loadState: LoadState;
  loadError?: string;
  projectConfig: ProjectConfig;
  collectionsFC?: (props: { id?: string; location?: string }) => React.ReactNode;
}

const EvidenceApp = (props: EvidenceAppProps) => {
  usePageTitle(props.projectConfig.explorerTitle);

  const [queryState, setQueryState] = useQueryState();

  const tagFilters = React.useMemo(
    () => ({
      suspect: new Set<string>(toArray(queryState.suspect)),
      otherTags: new Set<string>(toArray(queryState.other_tags)),
    }),
    [queryState.suspect, queryState.other_tags]
  );
  const setTagFilters = <K extends keyof TagFilters>(key: K, newValue: TagFilters[K]): void => {
    setQueryState((prevState) => {
      const queryKey = key === 'otherTags' ? 'other_tags' : key;
      return {
        ...prevState,
        [queryKey]: Array.from(newValue),
      };
    });
  };

  const keywords = React.useMemo(() => toArray(queryState.keywords), [queryState.keywords]);
  const setKeywords = (nextKeywords: string[]) => {
    setQueryState((prevState) => ({
      ...prevState,
      keywords: nextKeywords,
    }));
  };

  const QUERY_TRUE = 'true';
  const QUERY_FALSE = 'false;';
  const booleanFromQueryParam = (param: any, defaultValue: boolean): boolean =>
    param ? param === QUERY_TRUE : defaultValue;

  const toggles: Toggles = React.useMemo(
    () => ({
      // default to true if no value set in query states
      includeUntimestamped: booleanFromQueryParam(queryState.includeUntimestamped, true),
      includeUnlocated: booleanFromQueryParam(queryState.includeUnlocated, true),
    }),
    [queryState.includeUntimestamped, queryState.includeUnlocated]
  );
  const setToggles = <K extends keyof Toggles>(toggleName: K, newValue: boolean): void => {
    setQueryState((prevState) => ({
      ...prevState,
      [toggleName]: newValue ? QUERY_TRUE : QUERY_FALSE,
    }));
  };

  const parsedStartEndTimes =
    typeof queryState.start_end_times === 'string' ? queryState.start_end_times.split(',') : null;
  const startEndTimes: [Date, Date] | null = React.useMemo(
    () =>
      parsedStartEndTimes
        ? [
            new Date(parseInt(parsedStartEndTimes[0], 10)),
            new Date(parseInt(parsedStartEndTimes[1], 10)),
          ]
        : null,
    [parsedStartEndTimes]
  );

  const setStartEndTimes = React.useCallback((newTimes: [Date, Date] | null) => {
    // eslint-disable-next-line camelcase
    setQueryState(({ start_end_times, ...prevState }) => ({
      ...prevState,
      ...(newTimes
        ? { start_end_times: [newTimes[0].getTime(), newTimes[1].getTime()].join(',') }
        : {}),
    }));
  }, []);

  const [bounds, setBounds] = React.useState<LngLatBounds | null>(null);

  const exportView: boolean = booleanFromQueryParam(queryState.exportView, false);
  const setExportView = (newValue: boolean): void => {
    setQueryState((prevState) => ({
      ...prevState,
      exportView: newValue ? QUERY_TRUE : undefined,
    }));
  };

  const { filteredEvidence, timelineFilteredEvidence, tagFilterChoices } = React.useMemo(
    () => filterEvidence(props.evidence, tagFilters, keywords, toggles, startEndTimes, bounds),
    [props.evidence, tagFilters, keywords, toggles, startEndTimes, bounds]
  );

  return exportView ? (
    <EvidenceExportView
      evidence={filteredEvidence}
      tagFilters={tagFilters}
      keywords={keywords}
      toggles={toggles}
      startEndTimes={startEndTimes || undefined}
      bounds={bounds || undefined}
      setExportView={setExportView}
      projectConfig={props.projectConfig}
    />
  ) : (
    <PageContentWrapper>
      <FilterBox
        onTagFilterChanged={setTagFilters}
        onKeywordsChanged={setKeywords}
        tagFilters={tagFilters}
        choices={tagFilterChoices}
        keywords={keywords}
        toggles={toggles}
        onToggleChanged={(toggleName, newValue) => {
          setToggles(toggleName, newValue);
        }}
        showSuspectFilters={PROJECT_CONFIG.showSuspectFilter}
      />
      <EvidenceViewer
        projectConfig={props.projectConfig}
        evidence={filteredEvidence}
        timedEvidence={timelineFilteredEvidence}
        startEndTimes={startEndTimes}
        setStartEndTimes={setStartEndTimes}
        hideExplorerLink
        rootPath={PATHS.evidence}
        getDetailPath={(detailId) => `${PATHS.evidence}/${detailId}`}
        setBounds={setBounds}
        setExportView={setExportView}
        collectionsFC={props.collectionsFC}
        loadState={props.loadState}
        loadError={props.loadError}
      />
    </PageContentWrapper>
  );
};

export default EvidenceApp;
