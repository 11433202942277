/* CONSTANTS */
const colors = {
  tableLines: '#999',
  titleBg: '#99ddbb',
  headerBg: '#bbffcc',
  link: '#374',
  linkHover: '#051',
  activeFilter: '#f0f099',
  activeFilterHover: '#ff0',
  /* tag colors */
  person: '#bbe',
  group: '#ffe069',
  behavior: '#fcb',
  equipment: '#d0dd99',
};

const shadows = {
  lg: '0px 1px 12px rgba(0, 0, 0, 0.3)',
  sm: '1px 1px 2px rgba(0, 0, 0, 0.2)',
};

const padding = {
  xs: 3,
  sm: 6,
  md: 12,
  lg: 24,
  xl: 48,
  xxl: 72,
};

const heights = {
  navbar: 55,
};

const widths = {
  filterbox: 240,
};

export { colors, shadows, padding, heights, widths };
