import React from 'react';
import { Link } from 'react-router-dom';

import { PATHS } from '@jan6evidence/routes';

const MobileOverlay = () => {
  const [open, setOpen] = React.useState(true);

  return (
    (open && (
      <div
        style={{
          zIndex: 100,
          position: 'absolute',
          width: '100%',
          height: '100vh',
          background: 'rgba(255, 255, 255, 0.95)',
          padding: '30px',
        }}
        className="d-sm-none"
      >
        <h2>January 6 Evidence</h2>
        <p>
          You&rsquo;ll probably find the evidence explorer easier to use on a larger device or in a
          bigger window.
        </p>
        <ul>
          <li>
            <Link
              to={PATHS.evidence}
              onClick={(event) => {
                event.preventDefault();
                setOpen(false);
                return false;
              }}
            >
              Take me to the evidence anyway
            </Link>
          </li>
          <li>
            <Link to={PATHS.suspects}>Persons of Interest</Link>
          </li>
          <li>
            <Link to={PATHS.about}>About</Link>
          </li>
          <li>
            <Link to={PATHS.resources}>Resources for investigators of the Capitol Attack</Link>
          </li>
        </ul>
        <img
          src="/documentation/explorer-overview.jpg"
          style={{ width: '100%', margin: '0px auto' }}
          alt="Example explorer view"
        />
      </div>
    )) ||
    null
  );
};

export default MobileOverlay;
