import { url } from 'inspector';

import { latLngFormatter, videoTimepointFormatter } from '@jan6evidence/formatters';
import { Evidence } from '@jan6evidence/types';

type FormData = {};

const formatTags = (tags: Array<string> | undefined): string | undefined => {
  if (tags && tags.length > 0) {
    return tags.join(', ');
  } else {
    return undefined;
  }
};

const evidenceUpdateFormUrl = (evidence: Evidence): string => {
  const formUrl = new URL(
    'https://docs.google.com/forms/d/e/1FAIpQLScCg7u21sUuCbO6lBKdTqxoSH4j2NiLGrWi5cliwCRra8O5UQ/viewform'
  );
  const formFields = {
    usp: 'pp_url', // some standard google thing
    'entry.1011702585': 'Modify an entry',
    'entry.1623428240': evidence._id,
    'entry.789172520': evidence.imageUrl,
    'entry.523480783': evidence.videoUrl,
    'entry.1776265168': videoTimepointFormatter(evidence.videoStartTimepoint),
    'entry.1043855916': videoTimepointFormatter(evidence.videoEndTimepoint),
    'entry.1394213472': evidence.locationName?.replace(/\n {2}/, ' '),
    'entry.807096470': latLngFormatter(evidence.location),
    'entry.1510850464': formatTags(evidence.suspectTags),
    'entry.161204806': formatTags(evidence.otherTags),
    'entry.1939650052': evidence.summary,
    // Date and time not working yet
    //   'entry.325605150': evidence.realTimeStart?.toLocaleDateString()
    //   'entry.325605150': timeFormatter(evidence.realTimeStart, 'HH:mm'),
    'entry.129842460': evidence.rationale,
  };
  Object.entries(formFields).forEach(([key, value]) => {
    if (value && value.trim() !== '') {
      formUrl.searchParams.append(key, value);
    }
  });
  return formUrl.href;
};

export default evidenceUpdateFormUrl;
