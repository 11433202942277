/* eslint-ignore import/no-extraneous-dependencies */

import { DateTime } from 'luxon';
import React from 'react';
import ReactMarkdown from 'react-markdown';

import { localTimeRangeFormatter, TimeFormatterConfig } from '@jan6evidence/formatters';
import type { Evidence } from '@jan6evidence/types';

import { LocationLink } from '../shared/LocationLink';
import Tag from '../shared/Tag';

import { colors } from '../shared/constants';

type Props = {
  data: Evidence;
  isActive: boolean;
  activate: () => void;
};

export function ResultThumbnail({ cellData: data }: { cellData: Evidence }) {
  const type = data.videoUrl ? 'video' : 'photo';
  const formattedDuration =
    data.videoStartTimepoint && data.videoEndTimepoint
      ? data.videoEndTimepoint - data.videoStartTimepoint
      : '?';

  return (
    <div className="thumbnail-wrapper">
      <img className={type} src={data.imageUrl || data.videoDetails?.thumbnailUrl} alt="" />
      <span className="type">{type[0].toUpperCase() + type.slice(1)}</span>
      {type === 'video' && (
        <>
          <span className="triangle" />
          <span className="duration">{formattedDuration}</span>
        </>
      )}
    </div>
  );
}

// ReactMarkdown is slow enough that we want to memoize this table cell when possible.
export const ResultSummary = React.memo(({ cellData: data }: { cellData: Evidence }) => {
  const summary = data.summary || '';

  return (
    <div className="summary">
      <ReactMarkdown>{summary}</ReactMarkdown>
    </div>
  );
});

export function ResultTags({ cellData: data }: { cellData: Evidence }) {
  return (
    <div className="tags-wrapper">
      {data.suspectTags &&
        data.suspectTags.map((label) => (
          <Tag
            key={`${data._id}-person-${label}`}
            id={`${data._id}-person-${label}`}
            label={label}
            color={colors.person}
            title={label}
          />
        ))}
      {data.otherTags &&
        data.otherTags.map((label) => (
          <Tag
            key={`${data._id}-other-${label}`}
            id={`${data._id}-other-${label}`}
            label={label}
            title={label}
          />
        ))}
    </div>
  );
}

export function ResultTime({
  cellData: data,
  timeFormatterConfig,
}: {
  cellData: Evidence;
  timeFormatterConfig: TimeFormatterConfig;
}) {
  const timeStr = localTimeRangeFormatter(
    { start: data.realTimeStart, end: data.realTimeEnd },
    timeFormatterConfig
  );

  return <span className="time">{timeStr}</span>;
}

export function ResultLocation({ cellData: data }: { cellData: Evidence }) {
  if (data.location) {
    return <LocationLink latLng={data.location} name={data.locationName} />;
  } else {
    return <span className="location">?</span>;
  }
}
