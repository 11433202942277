import chroma from 'chroma-js';

import type { Evidence, Location, Suspect } from '@jan6evidence/types';

// Dates are serailized as strings, so we need to manually deserialize
// Would be nice to move this deserializaiton to ../data/index.ts but we tried
// and ran into compile errors, so we're doing it here for now.
const toDate = (str: string | undefined): Date | undefined => {
  if (!str) {
    return undefined;
  }
  try {
    return new Date(str);
  } catch {
    return undefined;
  }
};

const HASH_BUCKETS = 65521;
const A_SMALL_PRIME = 257;
class BadButFastRng {
  private accum: number;

  constructor(seed: string) {
    let accum = 1;
    for (let i = 0; i < seed.length; i++) {
      accum = (accum * A_SMALL_PRIME + seed.charCodeAt(i)) % HASH_BUCKETS;
    }
    this.accum = accum;
  }

  randFloatBetween(min: number, max: number): number {
    this.accum = (this.accum * A_SMALL_PRIME) % HASH_BUCKETS;
    return min + ((max - min) * this.accum) / HASH_BUCKETS;
  }
}

const JITTER = { lat: 0.00003, lng: 0.00003 };

const toLatLng = (location: Location, jitterSeed: string): [number, number] => {
  // jitterSeed with the evidence ID so that it's consistent from one render to another
  const r = new BadButFastRng(jitterSeed);
  return [
    location.lat + r.randFloatBetween(-JITTER.lat, JITTER.lat),
    location.lng + r.randFloatBetween(-JITTER.lng, JITTER.lng),
  ];
};

const toTimelinePercent = (
  time: Date | undefined,
  timelineDomainStart: number,
  timelineDomainEnd: number
): number | undefined => {
  if (!time) {
    return undefined;
  }
  return Math.max(
    0,
    Math.min(1, (time.getTime() - timelineDomainStart) / (timelineDomainEnd - timelineDomainStart))
  );
};

// 8am 10am 12pm 2pm 4pm 6pm

const colors = chroma.scale(['#f30', '#ff0', '#099', '#09f']).padding([-0.8, 0]).colors(40);

const makeColor = (timelineStartPercent: number | undefined): string => {
  if (timelineStartPercent === undefined) {
    return '#ccc';
  }
  const colorIndex = Math.floor(timelineStartPercent * colors.length);
  const clampedColorIndex = Math.min(Math.max(colorIndex, 0), colors.length - 1);
  return colors[clampedColorIndex];
};

// eslint-disable-next-line import/prefer-default-export
export { makeColor, toLatLng, toTimelinePercent };
