import { css } from 'styled-components';

import { padding, widths, colors, shadows } from './constants';

export const TextButton = css`
  border: 0;
  background: none;

  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }
  &:active {
    background: rgba(0, 0, 0, 0.4);
    color: white;
  }
`;

export const LeftBox = css`
  flex: 0 0 ${widths.filterbox}px;
  width: ${widths.filterbox}px;
  min-height: 100%;
  max-height: 100%;

  // box-shadow: ${shadows.lg};
  background-color: #ddd;
  border-right: 1px solid ${colors.tableLines};

  display: flex;
  flex-direction: column;
`;
