import styled from 'styled-components';

import { colors, shadows, padding } from '../shared/constants';

const BORDER_RADIUS = padding.md;
const MODAL_SPACING = 40;
const HEADER_HEIGHT = 64;

export const ModalWrapper = styled.section`
  position: absolute;
  transform: translate(0, 0); // hack to make the prev/next buttons appear properly
  top: ${MODAL_SPACING}px;
  left: ${MODAL_SPACING}px;
  right: ${MODAL_SPACING}px;
  bottom: ${MODAL_SPACING}px;
  background: white;
  border-radius: ${BORDER_RADIUS}px;
  border: 2px solid ${colors.titleBg};
  box-shadow: ${shadows.lg};

  display: flex;
  flex-direction: column;

  a {
    color: ${colors.link};

    &:hover {
      color: ${colors.linkHover};
    }
  }
`;

export const Header = styled.header`
  flex: 0 0 auto;
  background: ${colors.headerBg};
  position: relative;
  border-radius: ${BORDER_RADIUS}px ${BORDER_RADIUS}px 0 0;
  padding: ${padding.md}px;
  height: ${HEADER_HEIGHT}px;

  h2 {
    margin: 0;
    padding: 0;
  }
`;

export const Buttons = styled.nav`
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  height: ${HEADER_HEIGHT}px;

  button {
    &.close-button {
      background: 0;
      border: 0;
      font-size: 32px;
      line-height: 32px;
      color: #555;
      display: flex;
      align-items: baseline;

      font-size: 36px;
      line-height: 36px;
      padding: ${padding.md}px ${padding.lg}px;

      &:not(:disabled) {
        color: ${colors.link};
      }

      &:hover:not(:disabled) {
        color: ${colors.linkHover};

        background: rgba(255, 255, 255, 0.3);
      }
    }
  }
`;

export const Content = styled.main`
  flex: 1 1 auto;
  padding: ${padding.md}px;
  overflow-y: auto;
  overflow-x: hidden;

  .media {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #333;
    margin: ${-padding.md}px ${-padding.md}px 0 ${-padding.md}px;

    a {
      color: ${colors.headerBg};

      &:hover {
        color: ${colors.titleBg};
      }
    }

    img {
      max-height: calc(100vh - ${MODAL_SPACING * 2 + HEADER_HEIGHT * 3}px);
    }
    video {
      max-height: calc(100vh - ${MODAL_SPACING * 2 + HEADER_HEIGHT * 5}px);
    }

    span {
      display: block;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 0 ${padding.md}px;
      text-align: center;
    }
  }

  .media + .media {
    margin-top: 0px;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    max-width: 708px;
    margin: 0 auto;

    th {
      vertical-align: top;
    }

    td {
      padding: 0 0 0 ${padding.md}px;
    }

    th,
    td {
      padding-top: ${padding.md}px;
    }
  }
`;

const buttonBorderWidth = '3px';

export const Nav = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: space-between;

  button {
    height: 72px;
    width: 60px;
    font-size: 72px;
    line-height: 72px;
    padding-left: 7px;
    background-color: ${colors.headerBg};
    color: ${colors.link};
    cursor: pointer;

    border-top-right-radius: ${BORDER_RADIUS}px;
    border-bottom-left-radius: ${BORDER_RADIUS}px;
    border: 0;
    border: ${buttonBorderWidth} solid ${colors.titleBg};
    margin-bottom: -${buttonBorderWidth};
    margin-left: -${buttonBorderWidth};

    &:hover {
      color: ${colors.linkHover};
      border-color: ${colors.link};
    }
    &:active {
      background-color: ${colors.titleBg};
    }

    &:disabled,
    &:disabled:hover,
    &:disabled:active {
      cursor: auto;
      background-color: #ddd;
      color: #888;
      border-color: #aaa;
    }
  }

  button + button {
    margin-left: 0;
    margin-right: -${buttonBorderWidth};
    padding-right: 4px;
    border-radius: 0;
    border-top-left-radius: ${BORDER_RADIUS}px;
    border-bottom-right-radius: ${BORDER_RADIUS}px;
  }
`;
