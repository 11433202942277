import styled from 'styled-components';

import { heights } from './shared/constants';

const FixedLayout = styled.div`
  position: fixed;
  top: ${heights.navbar}px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  border-top: solid 1px #ccc;
`;

export default FixedLayout;
