import styled from 'styled-components';

import { padding, colors } from '../shared/constants';

export const MapWrapper = styled.div`
  flex: 0 0 auto;
  background-color: #ded;
  border: 1px solid #9a9;
  height: 300px;
  width: 100%;
  position: relative;

  .pointer-events-none {
    pointer-events: none;
  }
`;

export const MapSpinner = styled.div`
  background: rgba(255, 255, 255, 0.8);
  color: #000;
  position: absolute;
  top: 5px;
  left: 5px;
  padding: 5px 10px;
  font-size: 0.9em;
`;

export const MapControls = styled.div`
  background: rgba(255, 255, 255, 0.8);
  color: #000;
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 10px;
  font-size: 0.9em;
`;

const EVIDENCE_COLORS = {
  photo: '#6699ff',
  video: '#cc2266',
  clip: 'orange',
};

export const TimelineWrapper = styled.div`
  flex: 0 0 auto;
  height: 120px;
  padding: ${padding.sm}px ${padding.lg}px;

  overflow-x: hidden;

  background: #111;
  color: white;

  &:hover .handle {
    fill: #f0f0f0;
  }

  .selected-time {
    margin-left: -${padding.sm}px;
    margin-bottom: ${padding.sm}px;
  }

  .tick {
    background-color: white;
  }
`;

export const HandleShape = styled.svg`
  fill: #ddd;

  &.disabled {
    fill: #999;
  }
`;

export const TableWrapper = styled.div`
  flex: 1;

  .active {
    box-shadow: inset 0 0 0 6px #ff0;
  }

  .table-row {
    border-bottom: 1px solid ${colors.tableLines};

    &.highlighted {
      background-color: ${colors.activeFilter};
    }
  }

  img {
    min-height: 120px;
    width: 100%;
    background: #f0f0f0;
  }

  .thumbnail-wrapper {
    position: relative;
    font-size: 13px;
    color: white;

    img {
      min-height: 120px;
      width: 100%;
      background: #333;
      border: 3px solid black;

      &.video {
        border-color: ${EVIDENCE_COLORS.video};
      }
      &.clip {
        border-color: ${EVIDENCE_COLORS.clip};
      }
      &.photo {
        border-color: ${EVIDENCE_COLORS.photo};
      }
    }

    .triangle {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OCIgaGVpZ2h0PSI0OCIgdmlld0JveD0iMCAwIDQ4IDQ4IiBmaWxsPSIjZmZmIj48cGF0aCBkPSJNMjQgNEMxMi45NSA0IDQgMTIuOTUgNCAyNHM4Ljk1IDIwIDIwIDIwIDIwLTguOTUgMjAtMjBTMzUuMDUgNCAyNCA0em0tNCAyOVYxNWwxMiA5LTEyIDl6Ii8+PC9zdmc+);
      color: #fff;
      background-size: 48px 48px;
      height: 48px;
      width: 48px;
      opacity: 0.8;
    }

    .duration {
      position: absolute;
      bottom: 6px;
      right: 6px;
      opacity: 0.8;
    }

    .type {
      position: absolute;
      top: 6px;
      left: 6px;
      opacity: 0.8;
    }
  }

  .tags-wrapper {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    font-size: 13px;
  }

  .summary,
  .time,
  .location {
    height: 100px;
    white-space: normal;
  }
`;

export const NoResults = styled.div`
  text-align: center;
  padding: ${padding.lg}px;
`;
