/* eslint-disable import/no-unresolved */

import type { FeatureCollection, Point } from 'geojson';
import { LngLatBounds, LngLat } from 'mapbox-gl';
import * as React from 'react';

import InteractiveMap, { Source, Layer, Marker, MapRef } from 'react-map-gl';
import type { LayerProps, MapEvent } from 'react-map-gl';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { PATHS } from '@jan6evidence/routes';
import type { Evidence } from '@jan6evidence/types';

import { useQueryState, useDebouncedValue } from '../shared/hooks';

import { MapWrapper, MapSpinner, MapControls } from './evidence-results-styled-components';

const evidenceLayerStyle: LayerProps = {
  id: 'evidence',
  type: 'circle',
  paint: {
    'circle-radius': 5,
    'circle-color': ['get', 'color'],
    'circle-stroke-width': 2,
    'circle-stroke-color': '#000',
    'circle-stroke-opacity': 0.3,
  },
};
const evidenceHoverLayerStyle: LayerProps = {
  id: 'evidence-hover',
  source: 'evidence',
  type: 'circle',
  paint: {
    'circle-radius': 5,
    'circle-color': '#fff',
    'circle-stroke-width': 2,
    'circle-stroke-color': '#000',
    'circle-stroke-opacity': 0.4,
  },
};

const Tooltip = styled.div`
  background-color: #fff;
  padding: 4px 8px;
  border-radius: 3px;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.4);
  width: 180px;
  overflow: hidden;
  line-height: 1.4;
  font-size: 12px;
  margin-top: -5px;
  margin-left: 10px;
`;

type EvidenceWithLatLng = Evidence & { latLng: [number, number] };

// https://visgl.github.io/react-map-gl/docs/api-reference/interactive-map#onviewportchange
export type MapViewport = {
  latitude: number;
  longitude: number;
  zoom: number;
};

type Drawing = {
  anchor: number[] | null;
  corner: number[] | null;
} | null;

type Props = {
  evidence: Array<Evidence>;
  highlight: string;
  defaultMapViewport: MapViewport;
  onHover: (id: string) => void;
  onChangeBounds?: (bounds: LngLatBounds | null) => void;
};

const EvidenceMapbox = ({
  evidence,
  highlight,
  defaultMapViewport,
  onHover,
  onChangeBounds,
}: Props) => {
  const history = useHistory();
  const location = useLocation();

  const geojson: FeatureCollection<Point> = React.useMemo(() => {
    // Only consider evidence with locations.
    const locatedEvidence = evidence.filter((item): item is EvidenceWithLatLng => !!item.latLng);
    // Sort by start time, so that the gray markers land at the bottom
    // and the later-in-the-day markers are on top.
    locatedEvidence.sort((a, b) => +(a.realTimeStart || 0) - +(b.realTimeStart || 0));
    return {
      type: 'FeatureCollection',
      features: locatedEvidence.map((item) => {
        const [lat, lng] = item.latLng;
        const coordinates: [number, number] = [lng, lat];
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates,
          },
          properties: {
            id: item._id,
            color: item.color,
          },
        };
      }),
    };
  }, [evidence]);

  const [queryState, setQueryState] = useQueryState();

  const parseFloatString = (value: string | string[] | null | undefined) =>
    typeof value === 'string' ? parseFloat(value) : undefined;

  const viewport: MapViewport = {
    latitude: parseFloatString(queryState.latitude) ?? defaultMapViewport.latitude,
    longitude: parseFloatString(queryState.longitude) ?? defaultMapViewport.longitude,
    zoom: parseFloatString(queryState.zoom) ?? defaultMapViewport.zoom,
  };

  const setViewport = (nextViewport: MapViewport) => {
    setQueryState((prevQueryState) => ({
      ...prevQueryState,
      latitude: nextViewport.latitude.toString(),
      longitude: nextViewport.longitude.toString(),
      zoom: nextViewport.zoom.toString(),
    }));
  };

  // LngLatBounds expects an array of coordinate pairs, with the first coordinate pair referring to
  // the southwestern corner of the box (the minimum longitude and latitude) and the second
  // referring to the northeastern corner of the box (the maximum longitude and latitude).
  const toLngLatBounds = (lngLat1: number[], lngLat2: number[]) => {
    return new LngLatBounds(
      [Math.min(lngLat1[0], lngLat2[0]), Math.min(lngLat1[1], lngLat2[1])],
      [Math.max(lngLat1[0], lngLat2[0]), Math.max(lngLat1[1], lngLat2[1])]
    );
  };

  const drawingToLngLatBounds = (drawing: Drawing): LngLatBounds | null => {
    return drawing && drawing.anchor && drawing.corner
      ? toLngLatBounds(drawing.anchor, drawing.corner)
      : null;
  };

  const [drawing, setDrawing] = React.useState<Drawing>(null);
  const area: LngLatBounds | null = queryState.area
    ? LngLatBounds.convert(JSON.parse(queryState.area as string))
    : drawingToLngLatBounds(drawing);

  const [hoveredEvidence, setHoveredEvidence] = React.useState<Evidence | undefined>();
  const onMapHover = React.useCallback(
    (event) => {
      if (drawing) return;
      const feature = event.features && event.features[0];
      const hoveredItem = evidence.find((item) => item._id === feature?.properties?.id);
      onHover(hoveredItem?._id || '');
      setHoveredEvidence(hoveredItem);
    },
    [evidence, drawing]
  );
  const onMapClick = React.useCallback(
    (event) => {
      if (drawing) return;
      const feature = event.features && event.features[0];
      const clickedItem = evidence.find((item) => item._id === feature?.properties?.id);
      if (clickedItem) {
        history.push({ pathname: `${PATHS.evidence}/${clickedItem._id}`, search: location.search });
      }
    },
    [evidence, drawing]
  );
  const hoverLayerFilter = React.useMemo(() => {
    const id = hoveredEvidence?._id || highlight || '_none_';
    return ['==', id, ['get', 'id']];
  }, [hoveredEvidence, highlight]);

  const [loading, setLoading] = React.useState<boolean>(false);
  const mapRef = React.useRef<MapRef>(null);
  const map = mapRef.current?.getMap();
  const bounds = map?.getBounds();
  const serializedBounds = JSON.stringify(area && !drawing ? area.toArray() : bounds?.toArray()); // For useEffect dependency
  const debouncedBounds = useDebouncedValue(300, serializedBounds);
  React.useEffect(() => setLoading(true), [serializedBounds]);
  React.useEffect(() => {
    if (onChangeBounds && debouncedBounds) {
      const deserializedBounds = LngLatBounds.convert(JSON.parse(debouncedBounds));
      onChangeBounds(deserializedBounds);
    }
    return () => setLoading(false);
  }, [debouncedBounds]);

  const onMapMouseDown = (event: MapEvent) => {
    if (drawing) {
      setDrawing({ anchor: event.lngLat, corner: null });
    }
  };
  const onMapMouseMove = (event: MapEvent) => {
    if (drawing && drawing.anchor) {
      setDrawing({ ...drawing, corner: event.lngLat });
    }
  };
  const onMapMouseUp = () => {
    if (drawing && drawing.anchor && drawing.corner) {
      setQueryState((prevQueryState) => ({
        ...prevQueryState,
        area: JSON.stringify(area!.toArray()),
      }));
      setDrawing(null);
      onChangeBounds!(area);
    }
  };
  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setQueryState(({ area: _, ...restPrevQueryState }) => restPrevQueryState);
      setDrawing(null);
      onChangeBounds!(bounds);
    } else if (event.key === 'Shift') {
      setQueryState(({ area: _, ...restPrevQueryState }) => restPrevQueryState);
      setDrawing({ anchor: null, corner: null });
    }
  };
  const onKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'Shift') {
      if (drawing && !drawing.anchor) {
        setDrawing(null);
      }
    }
  };
  React.useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('keyup', onKeyUp);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [onKeyDown, onKeyUp]);

  return (
    <MapWrapper>
      <InteractiveMap
        {...viewport}
        width="100%"
        height="100%"
        onViewportChange={setViewport}
        mapboxApiAccessToken="pk.eyJ1IjoibWlzdGFrZW5tdXN0YXJkIiwiYSI6ImNrbGVkbWtkdzFrOXEyenRrejVyb2Zzc20ifQ.LkFY7eWOE8ryEW_6Kj0f_Q"
        mapStyle="mapbox://styles/mapbox/satellite-v9"
        interactiveLayerIds={
          evidenceLayerStyle.id && evidenceHoverLayerStyle.id
            ? [evidenceLayerStyle.id, evidenceHoverLayerStyle.id]
            : undefined
        }
        onHover={onMapHover}
        onClick={onMapClick}
        onMouseDown={onMapMouseDown}
        onMouseMove={onMapMouseMove}
        onMouseUp={onMapMouseUp}
        ref={mapRef}
        getCursor={() => (drawing ? 'crosshair' : 'grab')}
        dragPan={!drawing}
      >
        <Source type="geojson" data={geojson}>
          <Layer {...evidenceLayerStyle} />
          <Layer {...evidenceHoverLayerStyle} filter={hoverLayerFilter} />
        </Source>
        {hoveredEvidence && hoveredEvidence.latLng ? (
          <Marker
            latitude={hoveredEvidence.latLng[0]}
            longitude={hoveredEvidence.latLng[1]}
            className="pointer-events-none"
          >
            {hoveredEvidence.summary ? <Tooltip>{hoveredEvidence.summary}</Tooltip> : null}
          </Marker>
        ) : null}
        {area && (
          <Source
            id="box"
            type="geojson"
            data={{
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [
                  [
                    area.getNorthWest().toArray(),
                    area.getNorthEast().toArray(),
                    area.getSouthEast().toArray(),
                    area.getSouthWest().toArray(),
                    area.getNorthWest().toArray(),
                  ],
                ],
              },
              properties: {},
            }}
          >
            <Layer
              id="box"
              source="box"
              type="fill"
              paint={{ 'fill-color': 'rgba(255, 255, 255, 0.8)', 'fill-opacity': 0.5 }}
            />
          </Source>
        )}
      </InteractiveMap>
      {loading && <MapSpinner>Updating...</MapSpinner>}
      <MapControls>
        {drawing || !area ? (
          <button
            onClick={() => {
              if (drawing) {
                setDrawing(null);
              } else {
                setQueryState(({ area: _, ...restPrevQueryState }) => restPrevQueryState);
                setDrawing({ anchor: null, corner: null });
              }
            }}
            style={{ color: drawing ? '#007bff' : '' }}
            type="button"
          >
            Draw
          </button>
        ) : (
          <button
            onClick={() => {
              setQueryState(({ area: _, ...restPrevQueryState }) => restPrevQueryState);
              setDrawing(null);
              onChangeBounds!(bounds);
            }}
            style={{ color: drawing ? '#007bff' : '' }}
            type="button"
          >
            Clear
          </button>
        )}
      </MapControls>
    </MapWrapper>
  );
};

export default EvidenceMapbox;
