import qs from 'query-string';
import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export function usePageTitle(title: string) {
  React.useEffect(() => {
    const previousTitle = document.title;
    document.title = title;
    return () => {
      document.title = previousTitle;
    };
  }, [title]);
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const useDebouncedValue = (delay: number, value: any) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const useDebouncedValueWithWaitingIndicator = <T>(delay: number, value: T): [T, boolean] => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [isWaiting, setIsWaiting] = useState<boolean>(false);

  useEffect(() => {
    setIsWaiting(true);
    const timer = setTimeout(() => {
      setDebouncedValue(value);
      setIsWaiting(false);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return [debouncedValue, isWaiting];
};

export type QueryState = Record<string, string | string[] | null | undefined>;
type QueryStateUpdater = (prevState: QueryState) => QueryState;
export const useQueryState = (
  debounce: boolean = true,
  replaceState: boolean = false
): [QueryState, (updater: QueryStateUpdater) => void] => {
  const history = useHistory();
  const location = useLocation();

  const query: QueryState = qs.parse(location.search);
  const [queryState, setQueryState] = useState<QueryState>(query);

  const debouncedQueryState = debounce ? useDebouncedValue(300, queryState) : queryState;
  useEffect(() => {
    if (replaceState) {
      history.replace({ search: qs.stringify(debouncedQueryState) });
    } else {
      history.push({ search: qs.stringify(debouncedQueryState) });
    }
  }, [debouncedQueryState, replaceState]);

  useEffect(() => {
    const incomingQuery = location.search.replace(/^\?/, '');
    const expectedQuery = qs.stringify(debouncedQueryState);
    // Detect external query string changes and update our state accordingly
    if (incomingQuery !== expectedQuery) {
      const nextQuery: QueryState = qs.parse(location.search);
      setQueryState(nextQuery);
    }
  }, [location]);

  const update = (updater: QueryStateUpdater) => {
    const nextState = updater(queryState);
    setQueryState(nextState);
  };

  return [queryState, update];
};
