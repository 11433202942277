import React from 'react';

import type { TagFilters, TagFilterChoices, TagFilterChoice } from '../../types/TagFilters';
import Tag from '../shared/Tag';

import { FilterPills } from './filter-box-styled-components';

type Props = {
  tagFilters: TagFilters;
  choices: TagFilterChoices;
  keywords: string[];
  onDelete: (type: 'keywords' | keyof TagFilters, id: string) => void;
};

const ActiveFilters = ({ tagFilters, choices, keywords, onDelete }: Props) => {
  const tagFilterChoices = React.useMemo(() => {
    const result: (TagFilterChoice & { type: keyof TagFilters })[] = [];
    (Object.keys(tagFilters) as (keyof TagFilters)[]).forEach((filterKey) => {
      const sectionFilters: Set<string> = tagFilters[filterKey];
      const sectionChoices: TagFilterChoice[] | undefined = choices[filterKey];
      if (sectionChoices) {
        sectionFilters.forEach((id) => {
          const match = sectionChoices.find((element) => element._id === id);
          if (match) {
            result.push({ ...match, type: filterKey });
          }
        });
      }
    });
    return result;
  }, [tagFilters, choices]);

  const search = keywords.join(' ');

  return (
    <FilterPills>
      {tagFilterChoices.map(({ _id, type, label }) => (
        <Tag
          key={`active-${_id}`}
          id={_id}
          className={type}
          label={label}
          title={`${type[0].toUpperCase() + type.slice(1)}: ${label}`}
          onDelete={() => onDelete(type, _id)}
        />
      ))}
      {search.length > 0 && (
        <Tag
          key="active-keywords"
          id="search"
          label={search}
          className="keywords"
          title={`Keywords: ${search}`}
          onDelete={() => onDelete('keywords', search)}
        />
      )}
    </FilterPills>
  );
};

export default ActiveFilters;
