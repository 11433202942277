import React from 'react';

import { latLngFormatter } from '@jan6evidence/formatters';
import { Location as LatLng } from '@jan6evidence/types';

export const LocationLink = ({ latLng, name }: { latLng?: LatLng; name?: string }) => {
  if (latLng) {
    const googleMapUrl = `https://maps.google.com/?q=${latLng.lat},${latLng.lng}`;
    return (
      <span className="location">
        <a href={googleMapUrl} target="_blank" rel="noreferrer">
          {name || latLngFormatter(latLng)}
        </a>
      </span>
    );
  } else {
    return <span className="location">?</span>;
  }
};

export default LocationLink;
