import classnames from 'classnames';
import React from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import ToggleButton from 'react-bootstrap/ToggleButton';

import type { TagFilterChoiceWithCount } from '../../types/TagFilters';

type Props = {
  categoryId: string;
  choices: TagFilterChoiceWithCount[];
  choicesFilter: string;
  setChoicesFilter: (str: string) => void;
  selectedIds: Set<string>;
  onChange: (choiceIds: Set<string>) => void;
};

const TagFilterChoiceBox = ({
  categoryId,
  choices,
  choicesFilter,
  setChoicesFilter,
  selectedIds,
  onChange,
}: Props) => {
  const toggleSelection = (id: string) => {
    const newSelected = new Set(selectedIds);
    if (selectedIds.has(id)) {
      newSelected.delete(id);
    } else {
      newSelected.add(id);
    }
    onChange(newSelected);
  };

  return (
    <>
      {choices.length > 8 && (
        <Form.Control
          type="text"
          placeholder="Filter..."
          onChange={(e) => setChoicesFilter(e.target.value)}
          value={choicesFilter}
          size="sm"
          className="choice-filter"
        />
      )}
      <ButtonGroup toggle vertical>
        {choices.map((choice) => (
          <ToggleButton
            key={`${categoryId}-choice-${choice._id}`}
            title={choice.label}
            type="checkbox"
            variant="secondary"
            checked={selectedIds.has(choice._id)}
            value="1"
            onChange={() => toggleSelection(choice._id)}
            className={classnames({
              hidden:
                /* selectedIds.has(choice.id) || // TODO should chosen items disappear or go? */
                !choice.label.toLowerCase().includes(choicesFilter.toLowerCase()),
            })}
            style={{ borderRight: 'none' }}
          >
            <div className="label">{choice.label}</div>
            <div className="count">({choice.count})</div>
          </ToggleButton>
        ))}
      </ButtonGroup>
    </>
  );
};

export default TagFilterChoiceBox;
