import type { LngLatBounds } from 'mapbox-gl';

import type { Evidence } from '@jan6evidence/types';

import type {
  TagFilterChoicesWithCount,
  TagFilterChoiceWithCount,
  TagFilters,
  Toggles,
} from '../../types/TagFilters';

const isItemInTimeRange = (args: {
  itemStart: Date;
  itemEnd?: Date;
  rangeStart: Date;
  rangeEnd: Date;
}): boolean => {
  const { itemStart, itemEnd, rangeStart, rangeEnd } = args;
  // Use itemEnd if provided and after itemStart; otherwise treat item as instantaneous at itemStart.
  const usableItemEnd = itemEnd && itemEnd > itemStart ? itemEnd : itemStart;
  // Return true if any portion of the item's duration is in the range
  return itemStart <= rangeEnd && usableItemEnd >= rangeStart;
};

const doesEvidenceMatchKeywords = (evidence: Evidence, keywords: string[]): boolean => {
  if (keywords.length === 0) {
    return true;
  }

  return [
    evidence.locationName,
    ...(evidence.otherTags || []),
    ...(evidence.suspectTags || []),
    evidence.rationale,
    evidence.summary,
    evidence.mediaItemId,
    evidence.videoUrl,
    evidence.imageUrl,
  ].some((itemField) => {
    if (!itemField) {
      return false;
    }

    return keywords.some(
      (keyword) => itemField.toLocaleLowerCase().indexOf(keyword.toLocaleLowerCase()) >= 0
    );
  });
};

const isEvidenceInBounds = (evidence: Evidence, bounds: LngLatBounds): boolean => {
  return bounds.contains(evidence.location!);
};

export default function filterEvidence(
  evidence: Evidence[],
  tagFilters: TagFilters,
  rawKeywords: string[],
  toggles: Toggles,
  startEndTimes: [Date, Date] | null,
  bounds: LngLatBounds | null
): {
  filteredEvidence: Evidence[];
  timelineFilteredEvidence: Evidence[];
  tagFilterChoices: TagFilterChoicesWithCount;
} {
  const filteredEvidence: Evidence[] = [];
  const timelineFilteredEvidence: Evidence[] = [];

  const allSuspects: { [label: string]: TagFilterChoiceWithCount } = {};
  const allOtherTags: { [label: string]: TagFilterChoiceWithCount } = {};

  // helper for adding items to the filter choices
  function addTagFilterChoiceItem(
    tagFilterChoiceMap: { [label: string]: TagFilterChoiceWithCount },
    label: string
  ) {
    /* eslint-disable no-param-reassign */
    if (!tagFilterChoiceMap[label]) {
      tagFilterChoiceMap[label] = { _id: label, label, count: 0 };
    }

    tagFilterChoiceMap[label].count += 1;
    /* eslint-enable no-param-reassign */
  }

  const keywords = rawKeywords.filter((s) => s);

  evidence.forEach((item) => {
    // Keywords and toggles affect everything -- so apply those first and short-circuit
    // if the evidence doesn't match
    if (!doesEvidenceMatchKeywords(item, keywords)) {
      return;
    }

    if (!toggles.includeUntimestamped && !item.realTimeStart) {
      return;
    }

    if (!toggles.includeUnlocated && !item.location) {
      return;
    }

    // Compute which filters this item matches
    let matchSuspects = true;
    if (tagFilters.suspect.size > 0) {
      if (!item.suspectTags) {
        matchSuspects = false;
      } else if (!item.suspectTags.some((tag) => tagFilters.suspect.has(tag))) {
        matchSuspects = false;
      }
    }

    let matchTags = true;
    if (tagFilters.otherTags.size > 0) {
      if (!item.otherTags) {
        matchTags = false;
      } else if (!item.otherTags.some((tag) => tagFilters.otherTags.has(tag))) {
        matchTags = false;
      }
    }

    let matchTimeline = true;
    if (startEndTimes && item.realTimeStart) {
      if (
        !isItemInTimeRange({
          itemStart: item.realTimeStart,
          itemEnd: item.realTimeEnd,
          rangeStart: startEndTimes[0],
          rangeEnd: startEndTimes[1],
        })
      ) {
        matchTimeline = false;
      }
    }

    let matchBounds = true;
    if (bounds && item.location) {
      if (!isEvidenceInBounds(item, bounds)) {
        matchBounds = false;
      }
    }

    // If it matches all the filters, add it to the filtered evidence
    if (matchSuspects && matchTags && matchTimeline && matchBounds) {
      filteredEvidence.push(item);
    }

    // If it matches all the filters *ignoring timeline*, add it to the evidence
    // for the timeline (the timeline shows evidence outside the timeline
    // bounds)
    if (matchSuspects && matchTags && matchBounds) {
      timelineFilteredEvidence.push(item);
    }

    // Now, we need to compute the counts for the filter boxes. These filters
    // are ORs, not ANDs. So the count for each filter is the number of pieces
    // of evidence that match that specific filter item, *and* match the active
    // filters for the other types of filters. For example, the count for the
    // location filter "The Ellipse" is the number of pieces of evidence that
    // are tagged with "The Ellipse", AND match all active suspect and tag
    // filters.

    if (matchTags && matchTimeline && matchBounds && item.suspectTags) {
      item.suspectTags.forEach((tag) => addTagFilterChoiceItem(allSuspects, tag));
    }

    if (matchSuspects && matchTimeline && matchBounds && item.otherTags) {
      item.otherTags.forEach((tag) => addTagFilterChoiceItem(allOtherTags, tag));
    }
  });

  const tagFilterChoices = {
    suspect: Object.values(allSuspects).sort((a, b) => (a.label < b.label ? -1 : 1)),
    otherTags: Object.values(allOtherTags).sort((a, b) => (a.label < b.label ? -1 : 1)),
  };

  return { tagFilterChoices, filteredEvidence, timelineFilteredEvidence };
}
