/* eslint-disable import/prefer-default-export */

import fetch from 'isomorphic-fetch';

import { VideoDetailsArchiveOrg } from '@jan6evidence/types';

import { MediaUrlMatcher, MediaUrlMatch } from '../media_url_matcher';
import urlOrNull from '../util/urlOrNull';

export class ArchiveOrgUrlMatcher extends MediaUrlMatcher<VideoDetailsArchiveOrg> {
  async makeCanonical(
    urlAsString: string
  ): Promise<MediaUrlMatch<VideoDetailsArchiveOrg> | undefined> {
    // eslint-disable-next-line prefer-const
    let { identifier, filename } = this.getIdsFromUrl(urlAsString) || {};
    if (!identifier) {
      return undefined;
    }

    if (!filename) {
      const metadata = await this.getMetadata(identifier);
      const originalFilesMetadata = metadata.files?.filter((f: any) => f.source === 'original');
      const mp4s = originalFilesMetadata.filter((f: any) => f.format === 'MPEG4');
      const mkvs = originalFilesMetadata.filter((f: any) => f.format === 'Matroska');
      // choose the first mp4, or if there is none, the first mkv
      filename = mp4s[0]?.name || mkvs[0]?.name;
      if (!filename) {
        // no videos available, give up
        // eslint-disable-next-line no-console
        console.warn(
          `Unable to uniquely identify filename for archive.org item ${urlAsString}, ignoring`
        );
        return undefined;
      }
    }

    const startTimeParam = urlOrNull(urlAsString)?.searchParams?.get('start') || '';
    const startTime = parseInt(startTimeParam, 10) || undefined;

    return {
      canonicalUrl: `https://archive.org/download/${identifier}/${filename}`,
      mediaType: 'video',
      videoDetails: {
        type: 'archiveOrg',
        startTime,
        identifier,
        filename,
      },
    };
  }

  async getMetadata(identifier: string): Promise<any> {
    return (await fetch(`https://archive.org/metadata/${identifier}`)).json();
  }

  getIdsFromUrl(urlAsString: string): { identifier?: string; filename?: string } | undefined {
    const url = urlOrNull(urlAsString, { domain: 'archive.org' });
    if (!url) {
      return undefined;
    }

    // urls will be of the form
    // https://archive.org/<linktype>/<identifier> or
    // https://archive.org/<linktype>/<identifier>/filename
    //
    // where linktype = details, embed, download, ...
    const [identifier, ...filenameparts] = url.pathname.split('/').slice(2);
    const filename = filenameparts.join('/').replace(/\+/g, '%20') || undefined;
    if (identifier) {
      return { identifier, filename };
    } else {
      return undefined;
    }
  }
}
