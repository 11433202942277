import React from 'react';

import { Pill, PillXButton } from './shared-styled-components';

type Props = {
  id: string;
  label: string;
  className?: string;
  color?: string;
  title?: string;
  onDelete?: () => void;
};

const Tag = ({ id, title, className, color, label, onDelete }: Props) => (
  <Pill
    key={`tag-${title}-${id}`}
    style={color ? { backgroundColor: color } : {}}
    className={`${className}${onDelete ? ' has-delete' : ''}`}
    title={title || label}
  >
    {label}
    {onDelete && (
      <PillXButton title="Delete" type="button" onClick={onDelete}>
        &times;
      </PillXButton>
    )}
  </Pill>
);

export default Tag;
