/* eslint-disable import/prefer-default-export */
export const PATHS = {
  evidence: '/e',
  collections: '/c',
  suspects: '/gallery',
  suspectsTable: '/poi_table',
  about: '/about',
  resources: '/resources',
  mediaItems: '/media-items',
};
