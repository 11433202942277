import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { Tweet } from 'react-twitter-widgets';

import styled from 'styled-components';

import { PROJECT_CONFIG } from '@jan6evidence/settings';

import { PageHeaderAndNav } from './PageHeaderAndNav';

import Container from './shared/Container';
import { AppWrapper } from './table-styled-components';

const ResourcesDiv = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
`;

const ResourcesColumn = styled.div`
  padding: 30px;
  * + h2 {
    margin-top: 2rem;
  }
`;

function ResourcesPage() {
  return (
    <ResourcesDiv>
      <ResourcesColumn>
        <h2>Resources for investigators of the Capitol Attack</h2>
        <p>
          <b>Read this first</b>
        </p>
        <ul>
          <li>
            <a href="https://www.technologyreview.com/2021/01/14/1015931/how-to-be-an-ethical-online-investigator-activist/">
              How to be an ethical online investigator
            </a>
          </li>
        </ul>
        <p>
          <b>Locations</b>
        </p>
        <ul>
          <li>
            US Capitol Map:{' '}
            <a href="https://drive.google.com/file/d/1aQFDTYoTsYLRJ3qbPS7-4uMKEWtM6iRQ/view?usp=sharing">
              Grounds
            </a>
            ,{' '}
            <a href="https://drive.google.com/file/d/1DhIuY_lp3UW3FhmJcZuT0fmYLwOZAvfQ/view?usp=sharing">
              First Floor
            </a>
            ,{' '}
            <a href="https://drive.google.com/file/d/1NSQLNzefv9sowlu_pcQjHQC6he6ba5vr/view?usp=sharing">
              Second Floor
            </a>
            ,{' '}
            <a href="https://drive.google.com/file/d/1--4oBRMUlYBaTRmB9YhvCXROuzvsO7JR/view?usp=sharing">
              Third Floor
            </a>
          </li>
          <li>
            Guide to recognizing US Capitol locations:{' '}
            <a href="https://docs.google.com/document/d/1mC7V-FqjB4PzmAgzYlURG7AnGA_pdDnVsEh-7fFRToM/edit#heading=h.56y0ktk4a1f8">
              Exterior
            </a>
            ,{' '}
            <a href="https://docs.google.com/document/d/1hxXJrKhtDyf-1anm6g-wgp9DNNiy0dJp4KJKiNlXyAU/edit#">
              Interior
            </a>
          </li>
        </ul>
        <p>
          <b>Timing</b>
        </p>
        <ul>
          <li>
            <a href="https://docs.google.com/document/d/1uzKC6f9pPFQfBJM_jO-iNeFXQlZIP4ad_J4vizClENI/edit">
              Capitol Siege Timeline
            </a>
          </li>
          <li>
            <a href="https://docs.google.com/document/d/1aoUBFlERClHrNOoufBMiC7p0M15PrvyjM371sIG02Jw/edit">
              Visual Clues for Timing Capitol Siege Footage
            </a>
          </li>
        </ul>
        <p>
          <b>Persons of Interest</b>
        </p>
        <ul>
          <li>
            <a href="https://jan6attack.com/individuals.htm">Jan6Attack.com: Individuals</a>
          </li>
          <li>
            <a href="https://docs.google.com/spreadsheets/d/18NzncPJ-5oaYrDfq_VnjaSTLyY_vKaoSTgc6lznl9GU">
              Capitol Suspects Database
            </a>
          </li>
          <li>
            <a href="https://seditionhunters.org/perpsheet/">Sedition Hunters: Perp Sheet</a>
          </li>
          <li>
            <a href="https://www.fbi.gov/wanted/capitol-violence">
              FBI Most Wanted: Capitol Violence
            </a>
          </li>
          <li>
            <a href="https://mpdc.dc.gov/page/may-2020-february-2021-unrest-related-arrests-and-persons-interest">
              Metro Police Department: Persons of Interest
            </a>
          </li>
          <li>
            <a href="https://www.justice.gov/usao-dc/capitol-breach-cases">
              Department of Justice Cases
            </a>
          </li>
        </ul>
        <p>
          <b>Other Tools</b>
        </p>
        <ul>
          <li>
            <a href="http://bit.ly/bcattools">Bellingcat&rsquo;s Online Investigation Toolkit</a>
          </li>
          <li>
            <a href="https://www.detrumpify.org/pb_j6_marchers.php">Detrumpify.org</a>
          </li>
          <li>
            <a href="https://facesoftheriot.com/">Faces of the Riot</a>
          </li>
          <li>
            <a href="https://jan6attack.com">Jan6Attack.com</a>
          </li>
          <li>
            <a href="https://jan6archive.com">Jan6Archive.com</a>
          </li>
          <li>
            <a href="https://ne0ndistraction.github.io/posts/">ne0ndistraction: Parler Posts</a>
          </li>
          <li>
            <a href="http://capitolmap.com/faces">Patr10tic: Facial Recognition Tools</a>
          </li>
          <li>
            <a href="https://projects.propublica.org/parler-capitol-videos/">
              ProPublica: What Parler Saw
            </a>
          </li>
          <li>
            <a href="https://seditionhunters.org">SeditionHunters.org</a>
          </li>
        </ul>
      </ResourcesColumn>
    </ResourcesDiv>
  );
}

export default ResourcesPage;
