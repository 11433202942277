import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';

import { Route, Switch, useRouteMatch } from 'react-router';

import { PROJECT_CONFIG } from '@jan6evidence/settings';
import type { Suspect } from '@jan6evidence/types';

import LoadState from '../types/LoadState';

import PageHeaderAndNav from './PageHeaderAndNav';
import SuspectDetail from './SuspectDetail';
import SuspectsGallery, { SuspectFilters } from './SuspectsGallery';
import { usePageTitle } from './shared/hooks';
import { AppWrapper } from './table-styled-components';

export type GalleryItem = {
  mediaItemId: string;
  archiveUrl: string;
  thumbnailUrl?: string;
};

export type GalleryItemsInventory = { [primaryTag: string]: GalleryItem[] };

interface SuspectsAppProps {
  suspects: Suspect[];
  loadState: LoadState;
  loadError?: string | undefined;
}

const SuspectsApp = (props: SuspectsAppProps) => {
  usePageTitle('January 6 Persons of Interest');
  const match = useRouteMatch();

  const dateFormatter = (dateObj: Date) =>
    DateTime.fromJSDate(dateObj, { zone: PROJECT_CONFIG.timeFormat.zone }).toFormat('yyyy-MM-dd');

  const [prevSuspectFilters, setPrevSuspectFilters] = useState<SuspectFilters | undefined>(
    undefined
  );

  // async loading of items to appear in suspect detail
  const [detailItemsLoadState, setDetailItemsLoadState] = useState<LoadState>(LoadState.INIT);
  const [detailItemsLoadError, setDetailItemsLoadError] = useState<string>('');
  const [detailItemsInventory, setDetailItemsInventory] = useState<GalleryItemsInventory>({});

  useEffect(() => {
    fetch('/data/static-gallery-urls.json')
      .then((response) => response.text())
      .then((body) => {
        const res = JSON.parse(body) as GalleryItemsInventory;
        setDetailItemsInventory(res);
        setDetailItemsLoadState(LoadState.LOADED);
      })
      .catch((error) => {
        setDetailItemsLoadError(error.message);
        setDetailItemsLoadState(LoadState.ERROR);
      });
  }, []);

  return (
    <Switch>
      <Route path={`${match.path}/:suspectTag`}>
        <SuspectDetail
          suspects={props.suspects}
          loadState={props.loadState}
          loadError={props.loadError}
          dateFormatter={dateFormatter}
          prevSuspectFilters={prevSuspectFilters}
          detailItemsInventory={detailItemsInventory}
        />
      </Route>
      <Route path={match.path}>
        <SuspectsGallery
          suspects={props.suspects}
          loadState={props.loadState}
          loadError={props.loadError}
          setPrevSuspectFilters={setPrevSuspectFilters}
        />
      </Route>
    </Switch>
  );
};

export default SuspectsApp;
