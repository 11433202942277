// scraped from https://www.fbi.gov/wanted/capitol-violence
import FBI_POI_LINKS from './fbi_poi_links.json';

type numToStrObj = { [k: number]: string };

const getFbiLinksByFbiNumber = (): numToStrObj => {
  let result: numToStrObj = {};
  FBI_POI_LINKS.forEach((link: string) => {
    const regexMatch = link.match(/\d+/);
    if (regexMatch && regexMatch[0]) {
      const num = parseInt(regexMatch[0], 10);
      if (num && !result[num]) {
        result[num] = link;
      }
    }
  });
  return result;
};

export const fbiLinksByFbiNumber = getFbiLinksByFbiNumber();
