import styled from 'styled-components';

import { colors, widths, padding } from './shared/constants';

export const AppWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;

  h1 {
    padding: 6px;
    margin: 0;
    background: ${colors.titleBg};
  }
`;

export const TableWrapper = styled.div`
  position: relative;
  flex: 1 1 auto;
  background: #fcfcfc;

  overflow: scroll;
`;

const TableStyle = styled.table`
  border-spacing: 0;
  border-collapse: collapse;

  th,
  td {
    margin: 0;
    padding: 0 6px;
  }

  tbody tr {
    border-bottom: 1px solid ${colors.tableLines};
    &:hover {
      background: #f9f9f9;
    }
  }

  a {
    color: ${colors.link};
    &:hover {
      color: ${colors.linkHover};
    }
  }
`;

export const ScrollableTable = styled(TableStyle)`
  width: 100%;
`;

export const HeadFloater = styled(TableStyle)`
  position: absolute;
  top: 0;
`;

export const TableHead = styled.thead`
  background: white;
`;

export const StickyTH = styled.th`
  background: ${colors.headerBg};
  position: sticky;
  z-index: 10;

  .parent-category {
    border-bottom: 1px solid #051;
  }
`;

export const EvidenceResultsWrapper = styled.div`
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const EvidenceTableWrapper = styled.div`
  flex: 1 1 auto;

  border-top: 1px solid ${colors.tableLines};
  display: flex;
`;

export const EvidenceCount = styled.div`
  padding: ${padding.sm}px;
`;

export const ButtonLink = styled.button`
  border: 0;
  background: transparent;
  margin: 0;
  padding: 0;
  font-size: inherit;
  line-height: inherit;
  color: ${colors.link};
  text-decoration: underline;

  &:hover {
    color: ${colors.linkHover};
  }
`;
