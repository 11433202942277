import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { localTimeRangeFormatter, TimeFormatterConfig } from '@jan6evidence/formatters';
import { PATHS } from '@jan6evidence/routes';
import type { Evidence } from '@jan6evidence/types';

import { HighlightContext } from '../../shared/contexts';
import { RAIL_HEIGHT } from '../TimelineHelpers';

const timelineTitle = (evidence: Evidence, timeFormatterConfig: TimeFormatterConfig) => {
  return `[${localTimeRangeFormatter(
    { start: evidence.realTimeStart, end: evidence.realTimeEnd },
    timeFormatterConfig
  )}] ${evidence.summary}`;
};

const Stripe = React.memo(styled(Link)`
  display: block;
  position: absolute;

  width: 2px;
  height: ${RAIL_HEIGHT}px;

  transform: translate(-50%, 0);
  margin-top: 0;
  z-index: 2;

  cursor: pointer;

  &:hover,
  &.highlighted {
    background-color: white !important;
    z-index: 3;
  }
`);

type Props = {
  evidence: Evidence;
  highlighted: boolean;
  setHighlight: (id: string) => void;
  timeFormatterConfig: TimeFormatterConfig;
};

const EvidenceStripe = ({ evidence, highlighted, setHighlight, timeFormatterConfig }: Props) => {
  const location = useLocation();

  return (
    <Stripe
      to={{ pathname: `${PATHS.evidence}/${evidence._id}`, search: location.search }}
      title={highlighted ? timelineTitle(evidence, timeFormatterConfig) : ''}
      style={{
        left: `${(evidence.timelineStartPercent || 0) * 100}%`,
        backgroundColor: evidence.color,
      }}
      className={highlighted ? 'highlighted' : ''}
      onMouseOver={() => setHighlight(evidence._id)}
      onMouseOut={() => setHighlight('')}
    />
  );
};

export default React.memo(EvidenceStripe);
