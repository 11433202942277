/* eslint-disable no-console */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { fbiLinksByFbiNumber } from '@jan6evidence/data/fbi_links';
import POI_THUMBNAIL_DATA_CACHE from '@jan6evidence/data/poi_thumbnail_data_cache.json';
import {
  KeywordFilter,
  keywordFilterFromTextInput,
  isKeywordFilterMatch,
  YesNoEitherFilter,
  isYesNoFilterMatch,
} from '@jan6evidence/filters';
import { Suspect } from '@jan6evidence/types';

import LoadState from '../types/LoadState';

import { QueryState, useDebouncedValueWithWaitingIndicator, useQueryState } from './shared/hooks';

export const SuspectCardDiv = styled.div`
  padding: 0.25rem;
  flex: auto;
  min-width: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 280px;
  margin: 10px 5px;
  vertical-align: top;
`;

export const SuspectThumbnailImg = styled.img`
  width: auto;
  height: 200px;
  vertical-align: middle;
  border: solid 1px #eee;
`;

export const OverviewContainerDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  border-top: solid 1px #ccc;
`;

const FilterControlsDiv = styled.div`
  flex: 0 0 5.5rem;
  font-size: 0.8rem;
  padding: 0.8rem 15px;
  display: flex;
`;

const thumbnailFilename = (hashtag: string | undefined): string => {
  const lookupKey: string = hashtag?.toLowerCase() || 'no_such_key';
  // @ts-ignore
  const lookupResult: any = POI_THUMBNAIL_DATA_CACHE[lookupKey];
  return lookupResult?.thumbnailFilename || 'not_found.png';
};

export const SuspectThumbnail = ({ primaryHashtag }: { primaryHashtag: string | undefined }) => {
  const filename = thumbnailFilename(primaryHashtag);
  const thumbnailSrcUrl = `/poi_thumbnails/${filename}`;
  return <SuspectThumbnailImg src={thumbnailSrcUrl} alt={`${primaryHashtag} thumbnail image`} />;
};

// For now, point to TommyC's site. Replace with our own detail page once we have it!
const detailUrl = (suspect: Suspect): string => `gallery/${suspect.primaryHashtag}`;

const twitterSearchUrl = (suspect: Suspect): string =>
  `https://twitter.com/search?q=${suspect.primaryHashtag}`;

const getFbiLink = (suspect: Suspect) => {
  if (!suspect.fbiIdentifier) {
    return null;
  }
  const fbiNumberMatch = suspect.fbiIdentifier.match(/\d+/);
  if (!fbiNumberMatch) {
    return null;
  }
  const caption = `FBI: ${suspect.fbiIdentifier}`;
  const fbiUrl = fbiLinksByFbiNumber[parseInt(fbiNumberMatch[0], 10)];
  if (fbiUrl) {
    return <a href={fbiUrl}>{caption}</a>;
  } else {
    return <span>{caption}</span>;
  }
};

const SuspectCard = ({
  suspect,
  onDetailClick,
}: {
  suspect: Suspect;
  onDetailClick: () => void;
}) => {
  if (!suspect.primaryHashtag) {
    return null;
  }
  const fbiLink = getFbiLink(suspect);
  return (
    <SuspectCardDiv data-title={`suspect-${suspect._id}`}>
      <Link to={detailUrl(suspect)} onClick={onDetailClick}>
        <SuspectThumbnail primaryHashtag={suspect.primaryHashtag} />
      </Link>
      <a href={twitterSearchUrl(suspect)}>#{suspect.primaryHashtag}</a>
      {fbiLink || <span>&nbsp;</span>}
    </SuspectCardDiv>
  );
};

/// ///////
// Filters
/// ///////

export type SuspectFilters = {
  keyword: KeywordFilter;
  hasFbiIdentifier: YesNoEitherFilter;
  hasBeenCharged: YesNoEitherFilter;
};

const suspectMatchesKeywordFilter = (s: Suspect, keywordFilter: KeywordFilter): boolean => {
  const itemFields = [
    s.primaryHashtag,
    ...s.alternateHashtags,
    s.fbiIdentifier,
    s.realName,
    ...s.otherTags.map((t) => t.name),
  ].filter((field) => field) as string[];

  return isKeywordFilterMatch(itemFields, keywordFilter.parsed);
};

const suspectMatchesFbiFilter = (s: Suspect, fbiFilter: YesNoEitherFilter): boolean =>
  isYesNoFilterMatch(!!s.fbiIdentifier, fbiFilter);

const suspectMatchesChargedFilter = (
  s: Suspect,
  hasBeenChargedFilter: YesNoEitherFilter
): boolean => isYesNoFilterMatch(!!s.chargingDocUrl, hasBeenChargedFilter);

const applyFilters = (filters: SuspectFilters, suspects: Suspect[]): Suspect[] =>
  suspects.filter(
    (s) =>
      suspectMatchesChargedFilter(s, filters.hasBeenCharged) &&
      suspectMatchesFbiFilter(s, filters.hasFbiIdentifier) &&
      suspectMatchesKeywordFilter(s, filters.keyword)
  );

const countMessage = (filteredCount: number, totalCount: number): string =>
  [
    filteredCount,
    filteredCount === 1 ? 'person' : 'persons',
    totalCount !== filteredCount ? `of ${totalCount}` : '',
  ]
    .join(' ')
    .trim();

const FilterControls = ({
  filters,
  updateFilters,
  totalCount,
  filteredCount,
  keywordInputVal,
  setKeywordInputVal,
  isWaitingDebounce,
}: {
  filters: SuspectFilters;
  updateFilters: (f: Partial<SuspectFilters>) => void;
  totalCount: number;
  filteredCount: number;
  keywordInputVal: string;
  setKeywordInputVal: (val: string) => void;
  isWaitingDebounce: boolean;
}) => {
  return (
    <FilterControlsDiv>
      <div className="form-group col" style={{ flex: '0 0 16rem' }}>
        <label htmlFor="keywords" className="mb-0">
          Keywords and Tags
          <input
            className="form-control form-control-sm"
            name="keywords"
            id="keywords"
            type="text"
            value={keywordInputVal}
            onChange={(e) => setKeywordInputVal(e.target.value)}
            style={{ width: '15rem' }}
          />
        </label>
        <small className="form-text text-muted">
          Exclude terms with with &quot;<b>-</b>&quot;
        </small>
      </div>
      <div className="form-group col" style={{ flex: '0 0 9rem' }}>
        <label htmlFor="hasFbiIdentifier" className="mb-0">
          Has FBI Identifier?
          <select
            className="form-control form-control-sm"
            name="hasFbiIdentifier"
            value={filters.hasFbiIdentifier}
            onChange={(e) => {
              updateFilters({ hasFbiIdentifier: e.target.value as YesNoEitherFilter });
            }}
          >
            <option value=""> </option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </label>
      </div>
      <div className="form-group col" style={{ flex: '0 0 10rem' }}>
        <label htmlFor="hasBeenCharged" className="mb-0">
          Has been charged?
          <select
            className="form-control form-control-sm"
            name="hasBeenCharged"
            value={filters.hasBeenCharged}
            onChange={(e) => {
              updateFilters({ hasBeenCharged: e.target.value as YesNoEitherFilter });
            }}
          >
            <option value=""> </option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </label>
      </div>
      <div className="form-group col" style={{ flex: '0 0 12rem', fontSize: '0.9rem' }}>
        <br />
        {isWaitingDebounce ? 'Updating...' : countMessage(filteredCount, totalCount)}
      </div>
    </FilterControlsDiv>
  );
};

const SuspectOverview = ({
  filteredSuspects,
  loadState,
  loadError,
  onDetailClick,
}: {
  filteredSuspects: Array<Suspect>;
  loadState: LoadState;
  loadError?: string;
  onDetailClick: () => void;
}) => {
  // For a more responsive UI, render the first 20 suspects first, then add the rest.
  const PARTIAL_RENDER_LENGTH = 20;
  const [isPartialRender, setIsPartialRender] = useState<boolean>(true);
  useEffect(() => {
    setIsPartialRender(true);
  }, [filteredSuspects]);
  useEffect(() => {
    // after a partial render, re-render with the full list
    if (isPartialRender) {
      setIsPartialRender(false);
    }
  });

  return (
    <OverviewContainerDiv>
      {loadState === LoadState.LOADING ? <SuspectCardDiv>Loading...</SuspectCardDiv> : null}
      {loadState === LoadState.ERROR ? <SuspectCardDiv>Error: {loadError}</SuspectCardDiv> : null}
      {(isPartialRender ? filteredSuspects.slice(0, PARTIAL_RENDER_LENGTH) : filteredSuspects).map(
        (suspect) => (
          <SuspectCard key={suspect._id} suspect={suspect} onDetailClick={onDetailClick} />
        )
      )}
    </OverviewContainerDiv>
  );
};

const MemoizedSuspectOverview = React.memo(SuspectOverview);

const filtersFromQueryState = (queryState: QueryState): SuspectFilters => ({
  keyword: keywordFilterFromTextInput(typeof queryState.kw === 'string' ? queryState.kw : ''),
  hasBeenCharged: (typeof queryState.charged === 'string' &&
  ['yes', 'no'].includes(queryState.charged)
    ? queryState.charged
    : '') as YesNoEitherFilter,
  hasFbiIdentifier: (typeof queryState.fbi === 'string' && ['yes', 'no'].includes(queryState.fbi)
    ? queryState.fbi
    : '') as YesNoEitherFilter,
});

const queryStateUpdateFromNewFilters = (newFilters: Partial<SuspectFilters>) => {
  const result: any = {};
  if ('keyword' in newFilters) {
    result.kw = newFilters.keyword?.text || undefined;
  }
  if ('hasBeenCharged' in newFilters) {
    result.charged = newFilters.hasBeenCharged || undefined;
  }
  if ('hasFbiIdentifier' in newFilters) {
    result.fbi = newFilters.hasFbiIdentifier || undefined;
  }
  return result;
};

const queryStateFilterParams = (queryState: QueryState): string[] => [
  queryState.kw as string,
  queryState.charged as string,
  queryState.fbi as string,
];

const SuspectsGallery = ({
  suspects,
  loadState,
  loadError,
  setPrevSuspectFilters,
}: {
  suspects: Array<Suspect>;
  loadState: LoadState;
  loadError?: string;
  setPrevSuspectFilters: (sf: SuspectFilters | undefined) => void;
}) => {
  const [queryState, updateQueryState] = useQueryState(false, true);
  const filters = filtersFromQueryState(queryState);

  const filteredSuspects = useMemo(() => {
    return applyFilters(filters, suspects);
  }, [...queryStateFilterParams(queryState), suspects]);

  const onDetailClick = useCallback(() => {
    setPrevSuspectFilters(filters);
  }, [...queryStateFilterParams(queryState)]);

  const updateFilters = (newFilters: Partial<SuspectFilters>) => {
    updateQueryState((prevState) => ({
      ...prevState,
      ...queryStateUpdateFromNewFilters(newFilters),
    }));
  };

  const [keywordInputVal, setKeywordInputVal] = useState(filters.keyword.text);

  const [debouncedKeywordInputVal, isWaitingDebounce] = useDebouncedValueWithWaitingIndicator(
    200,
    keywordInputVal
  );

  useEffect(() => {
    const sanitizedInput = debouncedKeywordInputVal.replace(/#/, '');
    updateFilters({
      keyword: keywordFilterFromTextInput(sanitizedInput),
    });
  }, [debouncedKeywordInputVal]);

  return (
    <div>
      <div style={{ margin: '30px 30px 0px', maxWidth: '800px' }}>
        <h2>Persons-of-Interest Gallery</h2>
        <p style={{ fontSize: '0.9rem', marginBottom: '0px' }}>
          Data from the{' '}
          <a href="https://docs.google.com/spreadsheets/d/18NzncPJ-5oaYrDfq_VnjaSTLyY_vKaoSTgc6lznl9GU/edit#gid=0">
            Capitol Suspects Database
          </a>
          .
        </p>
      </div>
      <FilterControls
        filters={filters}
        updateFilters={updateFilters}
        totalCount={suspects.length}
        filteredCount={filteredSuspects.length}
        keywordInputVal={keywordInputVal}
        setKeywordInputVal={setKeywordInputVal}
        isWaitingDebounce={isWaitingDebounce}
      />
      <MemoizedSuspectOverview
        filteredSuspects={filteredSuspects}
        loadState={loadState}
        loadError={loadError}
        onDetailClick={onDetailClick}
      />
    </div>
  );
};

export default SuspectsGallery;
