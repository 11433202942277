import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { CSVDownload } from 'react-csv';
import { Link } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';
import styled from 'styled-components';

import {
  keywordFilterFromTextInput,
  isKeywordFilterMatch,
  KeywordFilter,
} from '@jan6evidence/filters';
import { videoTimepointFormatter } from '@jan6evidence/formatters';

import { EnrichedMediaItem, ArchiveForEnrichedMediaItem } from '@jan6evidence/types';
import { ArchiveOrgUrlMatcher } from '@jan6evidence/video-urls/types/archiveOrg';

import { useDebouncedValueWithWaitingIndicator } from './shared/hooks';

const FilterControlsDiv = styled.div`
  flex: 0 0 5.5rem;
  font-size: 0.8rem;
  padding: 0.8rem;
  display: flex;
`;

const ResultsSummaryDiv = styled.div`
  flex: 0 0 1rem;
  width: 100vw;
  font-size: 0.8rem;
  padding: 0 1.6rem 0.8rem;
  display: flex;
`;

const ItemsContainerDiv = styled.div`
  flex: 1 1 auto;
  width: 100vw;
  border-top: solid 1px #ccc;
`;

// This is the body default, we just want to be explicit because react-window needs the math
const itemDivLineHeight = 1.5;
const itemDivFontSizeRem = 0.8;

const ItemDiv = styled.div`
  font-size: ${itemDivFontSizeRem}rem;
  padding: ${itemDivFontSizeRem}rem;
  border-bottom: solid 1px #ccc;
  line-height: ${itemDivLineHeight};

  .item-attr {
    display: flex;
    .label {
      flex: 0 0 8rem;
      display: inline-block;
      color: #aaa;
      text-align: right;
      padding-right: 0.8rem;
    }

    .content {
      flex: 1 0 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

const maybeLink = ({ text, href }: { text: string; href?: string }) => {
  if (href) {
    return <a href={href}>{text}</a>;
  } else {
    return <span>{text}</span>;
  }
};

const ItemAttr: FunctionComponent<{ displayIf: any; label: string }> = ({
  displayIf,
  label,
  children,
}) => {
  if (displayIf) {
    return (
      <div className="item-attr">
        <div className="label">{label}</div>
        <div className="content">{children}</div>
      </div>
    );
  } else {
    return null;
  }
};

const ArchiveLink = ({ arch }: { arch: ArchiveForEnrichedMediaItem }) => {
  let str = 'Archive';
  if (arch.width && arch.height) {
    str += ` (${arch.width} x ${arch.height})`;
  }
  const { identifier: archiveOrgIdentifier } =
    new ArchiveOrgUrlMatcher().getIdsFromUrl(arch.archiveUrl) || {};

  const url = archiveOrgIdentifier
    ? `https://archive.org/details/${archiveOrgIdentifier}`
    : arch.archiveUrl;
  return (
    <a href={url} target="_blank" rel="noreferrer">
      {str}
    </a>
  );
};

const formatDate = (d: Date | undefined): string | undefined => {
  try {
    return d ? d.toISOString() : undefined;
  } catch {
    return undefined;
  }
};

const notArchivedInfo = (item: EnrichedMediaItem) => {
  if (item.sourceUnavailableReason && item.sourceUnavailableAt) {
    const dateStr = formatDate(item.sourceUnavailableAt);
    return `Couldn't archive at ${dateStr}: ${item.sourceUnavailableReason}`;
  } else {
    return 'Not archived';
  }
};

const itemHeight = (item: EnrichedMediaItem) => {
  // should exactly match all the displayIf's in the ItemAttrs of Item
  const attrCount = [
    true,
    item.sourceUrl,
    item.sourceTitle,
    item.sourceAttribution,
    item.sourceDuration,
    item.archives[0],
    item.archives[0]?.md5,
    item.additionalKeywords.length > 0,
    !item.archives[0],
    item.mediaType === 'video',
  ].filter((x) => !!x).length;

  const heightInRem = (2 + attrCount * itemDivLineHeight) * itemDivFontSizeRem;

  // 16px is the default rem. Seems to work great, even when you zoom.
  // Add one for the border.
  return heightInRem * 16 + 1;
};

const Item = ({
  item,
  annotationLinkPath,
  style,
}: {
  item: EnrichedMediaItem;
  annotationLinkPath: AnnotationLinkPathFn;
  style?: React.CSSProperties;
}) => {
  const archive = item.archives[0];
  return (
    <ItemDiv id={item._id} style={style}>
      <ItemAttr displayIf label="Media Item ID">
        {item._id}
      </ItemAttr>
      <ItemAttr displayIf={item.sourceUrl} label="Source URL">
        <a href={item.sourceUrl} target="_blank" rel="noreferrer">
          {item.sourceUrl}
        </a>
      </ItemAttr>
      <ItemAttr displayIf={item.sourceTitle} label="Title">
        {item.sourceTitle}
      </ItemAttr>
      <ItemAttr displayIf={item.sourceAttribution} label="by">
        {maybeLink({ text: item.sourceAttribution!, href: item.sourceAttributionUrl })}
      </ItemAttr>
      <ItemAttr displayIf={item.sourceDuration} label="Duration">
        {videoTimepointFormatter(item.sourceDuration)}
      </ItemAttr>
      <ItemAttr displayIf={archive} label="Archive">
        <ArchiveLink arch={archive} />
      </ItemAttr>
      <ItemAttr displayIf={archive?.md5} label="Archive md5">
        {archive?.md5}
      </ItemAttr>
      <ItemAttr displayIf={item.additionalKeywords.length > 0} label="Additional Terms">
        {item.additionalKeywords
          .sort()
          .map((str) => `"${str}"`)
          .join(', ')}
      </ItemAttr>
      <ItemAttr displayIf={!archive} label="Archive">
        {notArchivedInfo(item)}
      </ItemAttr>
      {
        // TODO: update this when annotator can handle image case...
      }
      <ItemAttr displayIf={item.mediaType === 'video'} label="Annotations">
        <Link to={annotationLinkPath(item)}>{item.annotationCount || '0'}</Link>
      </ItemAttr>
    </ItemDiv>
  );
};

const csvHeader = [
  'id',
  'sourceUrl',
  'sourceTitle',
  'sourceAttribution',
  'sourceAttributionUrl',
  'sourceDuration',
  'archiveUrl',
  'archivedAt',
  'archiveWidth',
  'archiveHeight',
  'archiveMd5',
  'additionalTerms',
  'sourceUnavailableAt',
  'sourceUnavailableReason',
  'annotationCount',
];

const itemAsCsvRow = (item: EnrichedMediaItem): { [k: string]: string | number | undefined } => {
  const archive = item.archives[0];
  return {
    id: item._id,
    sourceUrl: item.sourceUrl,
    sourceTitle: item.sourceTitle,
    sourceAttribution: item.sourceAttribution,
    sourceAttributionUrl: item.sourceAttributionUrl,
    sourceDuration: videoTimepointFormatter(item.sourceDuration),
    archiveUrl: archive?.archiveUrl,
    archivedAt: formatDate(archive?.archivedAt),
    archiveWidth: archive?.width,
    archiveHeight: archive?.height,
    archiveMd5: archive?.md5,
    additionalTerms: `${item.additionalKeywords.sort().join(', ')}`,
    sourceUnavailableAt: formatDate(item.sourceUnavailableAt),
    sourceUnavailableReason: item.sourceUnavailableReason,
    annotationCount: item.annotationCount,
  };
};

// Workaround b/c react-csv's claimed lazy-loading of data doesn't work...
// https://github.com/react-csv/react-csv/issues/72#issuecomment-1018081533
const LinkForCsvDownload = ({
  items,
  disabled,
}: {
  items: EnrichedMediaItem[];
  disabled: boolean;
}) => {
  const [initiated, setInitiated] = useState<boolean>(false);
  const itemsForCsv = items.map((i) => itemAsCsvRow(i));

  useEffect(() => {
    if (initiated) {
      setInitiated(false);
    }
  }, [initiated]);

  return (
    <>
      <Button
        className="btn btn-link btn-sm"
        style={{ fontSize: '0.8rem', padding: '0 0 0.8rem 0' }}
        variant="link"
        onClick={(e) => {
          e.preventDefault();
          setInitiated(true);
        }}
        disabled={disabled}
      >
        Download as CSV
      </Button>
      {initiated ? <CSVDownload data={itemsForCsv} headers={csvHeader} target="_blank" /> : null}
    </>
  );

  // return
};

type AnnotationLinkPathFn = (item: EnrichedMediaItem) => string;

type Props = {
  ready: boolean;
  items: EnrichedMediaItem[];
  annotationLinkPath: AnnotationLinkPathFn;
};

type MediaTypeFilter = 'video' | 'photo' | '';
type IsArchivedFilter = 'yes' | 'no' | '';

type Filters = {
  mediaType: MediaTypeFilter;
  keywordFilter: KeywordFilter;
  isArchived: IsArchivedFilter;
};

const matchesMediaType = (item: EnrichedMediaItem, mediaType: MediaTypeFilter): boolean =>
  mediaType === '' || item.mediaType === mediaType;

const itemMatchesKeywordFilter = (
  item: EnrichedMediaItem,
  keywordFilter: KeywordFilter
): boolean => {
  const itemFields = [
    item._id,
    item.sourceTitle,
    item.sourceUrl,
    item.sourceAttribution,
    item.sourceAttributionUrl,
    ...item.archives.map((arch) => arch.archiveUrl),
    ...item.additionalKeywords,
  ].filter((field) => field) as string[];

  return isKeywordFilterMatch(itemFields, keywordFilter.parsed);
};

const matchesIsArchived = (item: EnrichedMediaItem, isArchived: IsArchivedFilter): boolean => {
  if (isArchived === 'yes' && item.archives.length === 0) {
    return false;
  }
  if (isArchived === 'no' && item.archives.length !== 0) {
    return false;
  }
  return true;
};

const matchesFilters = (item: EnrichedMediaItem, filters: Filters): boolean =>
  matchesMediaType(item, filters.mediaType) &&
  matchesIsArchived(item, filters.isArchived) &&
  itemMatchesKeywordFilter(item, filters.keywordFilter);

const FilterControls = ({
  filters,
  setFilters,
  setWaitingForDebounce,
}: {
  filters: Filters;
  setFilters: (f: Filters) => void;
  setWaitingForDebounce: (v: boolean) => void;
}) => {
  const [keywordInputVal, setKeywordInputVal] = useState<string>('');
  const [debouncedKeywordInputVal, isWaitingDebounce] = useDebouncedValueWithWaitingIndicator(
    200,
    keywordInputVal
  );

  useEffect(() => {
    setFilters({
      ...filters,
      keywordFilter: keywordFilterFromTextInput(debouncedKeywordInputVal),
    });
  }, [debouncedKeywordInputVal]);

  useEffect(() => {
    setWaitingForDebounce(isWaitingDebounce);
  }, [isWaitingDebounce]);

  return (
    <FilterControlsDiv>
      <div className="form-group col" style={{ flex: '0 0 10rem' }}>
        <label htmlFor="mediaType-filter" className="mb-0">
          Media Type
          <select
            className="form-control form-control-sm"
            name="mediaType-filter"
            id="mediaType-filter"
            value={filters.mediaType}
            onChange={(e) =>
              setFilters({ ...filters, mediaType: e.target.value as MediaTypeFilter })
            }
          >
            {
              // enable Any and Photo options when ready
            }
            <option value="" disabled>
              Any
            </option>
            <option value="video">Video</option>
            <option value="photo" disabled>
              Photo
            </option>
          </select>
        </label>
      </div>
      <div className="form-group col" style={{ flex: '0 0 20rem' }}>
        <label htmlFor="keywords" className="mb-0">
          Keywords
          <input
            className="form-control form-control-sm"
            name="keywords"
            id="keywords"
            type="text"
            value={keywordInputVal}
            onChange={(e) => {
              setKeywordInputVal(e.target.value);
            }}
          />
        </label>
        <small className="form-text text-muted">
          To exclude terms, precede those terms with &quot;<b>-</b>&quot;
        </small>
      </div>
      <div className="form-group col" style={{ flex: '0 0 10rem' }}>
        <label htmlFor="isArchived" className="mb-0">
          Archive status
          <select
            className="form-control form-control-sm"
            name="isArchived"
            value={filters.isArchived}
            onChange={(e) =>
              setFilters({ ...filters, isArchived: e.target.value as IsArchivedFilter })
            }
          >
            <option value="">Any</option>
            <option value="yes">Archived</option>
            <option value="no">Not archived</option>
          </select>
        </label>
      </div>
    </FilterControlsDiv>
  );
};

const FilteredItems = ({
  items,
  filters,
  annotationLinkPath,
  waitingForDebounce,
}: {
  items: EnrichedMediaItem[];
  filters: Filters;
  annotationLinkPath: AnnotationLinkPathFn;
  waitingForDebounce: boolean;
}) => {
  const itemsForDisplay = items.filter((item) => matchesFilters(item, filters));
  return (
    <>
      <ResultsSummaryDiv>
        {waitingForDebounce ? (
          'Updating...'
        ) : (
          <>
            {itemsForDisplay.length} items{' '}
            {itemsForDisplay.length !== items.length ? `of ${items.length} ` : null}
          </>
        )}
        &bull;&nbsp;
        <LinkForCsvDownload items={itemsForDisplay} disabled={waitingForDebounce} />
      </ResultsSummaryDiv>

      <ItemsContainerDiv>
        {itemsForDisplay.length > 0 ? (
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height}
                itemCount={itemsForDisplay.length}
                itemSize={(index) => itemHeight(itemsForDisplay[index])}
                width={width}
              >
                {({ index, style }: { index: number; style: React.CSSProperties }) => (
                  <Item
                    item={itemsForDisplay[index]}
                    style={style}
                    annotationLinkPath={annotationLinkPath}
                  />
                )}
              </List>
            )}
          </AutoSizer>
        ) : (
          <ItemDiv>
            <div className="item-attr">
              <div className="label" />
              <div className="content">No matches</div>
            </div>
          </ItemDiv>
        )}
      </ItemsContainerDiv>
    </>
  );
};

const MediaItemsOverview = ({ ready, items, annotationLinkPath }: Props) => {
  const [filters, setFilters] = useState<Filters>({
    keywordFilter: keywordFilterFromTextInput(''),
    isArchived: '',
    mediaType: 'video',
  });
  const [waitingForDebounce, setWaitingForDebounce] = useState<boolean>(false);

  if (!ready) {
    return (
      <div style={{ padding: '1.6rem' }}>
        <p>Loading (please be patient)...</p>
        <br />
        <Spinner animation="border" role="status" variant="secondary">
          <span className="sr-only">Loading</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 1px)', // the -1px prevents apperance of unnecessary scrollbars
        alignItems: 'stretch',
        justifyContent: 'flex-start',
      }}
    >
      <h1 style={{ flex: '0 0 3rem', padding: '0.4rem 0 0 1.6rem' }}>Media Items</h1>
      <FilterControls
        filters={filters}
        setFilters={setFilters}
        setWaitingForDebounce={setWaitingForDebounce}
      />
      <FilteredItems
        items={items}
        filters={filters}
        annotationLinkPath={annotationLinkPath}
        waitingForDebounce={waitingForDebounce}
      />
    </div>
  );
};

export default MediaItemsOverview;
