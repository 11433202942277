import React, { useCallback, useState } from 'react';
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';

import {
  localTimeFormatter,
  localTimeRangeFormatter,
  TimeFormatterConfig,
} from '@jan6evidence/formatters';
import type { Evidence } from '@jan6evidence/types';

import { Range, SliderRail, Handle, Track, Tick } from './TimelineHelpers';
import { TimelineWrapper } from './evidence-results-styled-components';
import EvidenceStripe from './timeline/EvidenceStripe';

const sliderStyle = {
  position: 'relative',
  width: '100%',
};

// Returns min if (val < min), max if (val > max), and val otherwise.
function clamp(val: number, min: number, max: number): number {
  return Math.min(Math.max(val, min), max);
}

type EvidenceTimelineProps = {
  highlight: string;
  setHighlight: (id: string) => void;
  startEndTimes?: [Date, Date] | null;
  onChangeTimes?: (newTimes: [Date, Date] | null) => void; // if none, timeline is stripes only
  evidence: Evidence[];
  domainStart: number;
  domainEnd: number;
  tickIntervalMsec: number;
  timeFormatterConfig: TimeFormatterConfig;
};

export default function EvidenceTimeline({
  highlight,
  setHighlight,
  startEndTimes,
  onChangeTimes,
  evidence,
  domainStart,
  domainEnd,
  tickIntervalMsec,
  timeFormatterConfig,
}: EvidenceTimelineProps) {
  // The parent component passes in the currently-selected time range. We also
  // maintain this local state, which has the start/end values as you're dragging
  // the sliders. Once the user releases the slider, we pass the new values up to
  // the parent and discard this state.
  const [draggingValues, setDraggingValues] = useState<[number, number] | null>(null);

  const domain: Range = [domainStart, domainEnd];
  let values: Range;
  if (draggingValues) {
    values = draggingValues;
  } else if (startEndTimes) {
    values = [startEndTimes[0].getTime(), startEndTimes[1].getTime()];
  } else {
    values = [domainStart, domainEnd];
  }

  const onChange = useCallback(
    (newValues: readonly number[]) => {
      if (onChangeTimes) {
        if (newValues[0] === domainStart && newValues[1] === domainEnd) {
          onChangeTimes(null);
        } else {
          onChangeTimes([new Date(newValues[0]), new Date(newValues[1])]);
        }
      }
    },
    [domainStart, domainEnd, onChangeTimes]
  );

  const tickValues = [];
  let range = domainEnd - domainStart;
  while (range >= 0) {
    tickValues.push(range + domainStart);
    range -= tickIntervalMsec;
  }

  const evidenceStripes = React.useMemo(() => {
    return evidence.map((item) => (
      <EvidenceStripe
        key={item._id}
        evidence={item}
        highlighted={item._id === highlight}
        setHighlight={setHighlight}
        timeFormatterConfig={timeFormatterConfig}
      />
    ));
  }, [evidence, highlight, setHighlight, timeFormatterConfig]);

  return (
    <TimelineWrapper>
      <div className="selected-time">
        {localTimeRangeFormatter(
          {
            start: values[0],
            end: values[1],
          },
          timeFormatterConfig
        )}
      </div>
      <Slider
        mode={1}
        step={5}
        domain={domain}
        reversed={false}
        rootStyle={sliderStyle}
        onUpdate={(newValues) => setDraggingValues([newValues[0], newValues[1]])}
        onChange={onChange}
        values={values}
      >
        <Rail>{({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}</Rail>
        {onChangeTimes && (
          <Handles>
            {({ handles, getHandleProps }) => (
              <div className="slider-handles">
                {handles.map((handle) => (
                  <Handle
                    key={handle.id}
                    handle={handle}
                    domain={domain}
                    getHandleProps={getHandleProps}
                    isRight={handle.value > values[0]}
                  />
                ))}
              </div>
            )}
          </Handles>
        )}
        {evidenceStripes}
        {onChangeTimes && (
          <Tracks left={false} right={false}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) => (
                  <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
                ))}
              </div>
            )}
          </Tracks>
        )}
        <Ticks values={tickValues}>
          {({ ticks }) => (
            <div className="slider-ticks">
              {ticks.map((tick) => (
                <Tick
                  key={tick.id}
                  tick={tick}
                  count={ticks.length}
                  formatterFn={(t) => localTimeFormatter(t, timeFormatterConfig)}
                />
              ))}
            </div>
          )}
        </Ticks>
      </Slider>
      <div>
        {localTimeRangeFormatter({ start: values[0], end: values[1] }, timeFormatterConfig)}
      </div>
    </TimelineWrapper>
  );
}
