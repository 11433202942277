import { DateTime } from 'luxon';

import { localTimeFormatter } from '@jan6evidence/formatters';

import colombiaTags from './colombia-tags.json';
import jan6Tags from './jan6-tags.json';
import defaultSettings from './jan6_settings.json';
import usPoliceViolenceTags from './us_police_violence-tags.json';

declare let Meteor: any;
const meteorSettings = typeof Meteor !== 'undefined' ? Meteor.settings : undefined;

const rawProjectConfig =
  meteorSettings?.public?.projectConfig || defaultSettings?.public?.projectConfig;

/// ///////////

export type LocalTimeInputConfig = {
  inputType: 'date' | 'time';
  defaultDate?: Date;
  labelText: string;
  showInputPrecision: boolean;
  zone: string; // e.g. 'America/New_York'
  formatLocalTime: (time: Date | null) => string;
  dateTimeFromInputValue: (value: any) => DateTime;
};

const makeFormatLocalTimeFn = ({ zone, format }: { zone: string; format: string }) => {
  const fn = (time: Date | null): string =>
    time ? localTimeFormatter(time, { zone, format })! : '';
  return fn;
};

const makeDateTimeFromTimeInputValueFn = ({
  zone,
  defaultDate,
}: {
  zone: string;
  defaultDate: Date;
}) => {
  return (value: any): DateTime => {
    const [hours, minutes, seconds] = value.split(':');
    return DateTime.fromObject(
      {
        year: defaultDate!.getUTCFullYear(),
        month: defaultDate!.getMonth() + 1,
        day: defaultDate!.getUTCDate(),
        hour: hours,
        minute: minutes,
        second: seconds || 0,
      },
      // @ts-ignore the upstream type signatures for DateTime.fromObject are wrong :(
      { zone }
    );
  };
};

const makeDateTimeFromDateInputValueFn = ({ zone }: { zone: string }) => {
  return (value: any): DateTime => {
    const [year, month, day] = value
      .split('-')
      .map((x: string | undefined) => x && parseInt(x, 10));
    const dt = DateTime.fromObject(
      {
        year,
        month,
        day,
        hour: 0,
        minute: 0,
        second: 0,
      },
      // @ts-ignore the upstream type signatures for DateTime.fromObject are wrong :(
      { zone }
    );
    return dt;
  };
};

const localTimeInputZone = rawProjectConfig.localTimeInput.zone as string;
const defaultDate = rawProjectConfig.localTimeInput.defaultDate
  ? new Date(rawProjectConfig.localTimeInput.defaultDate)
  : undefined;
const format = rawProjectConfig.localTimeInput.inputType === 'date' ? 'yyyy-MM-dd' : 'HH:mm:ss';

const localTimeInputConfig = {
  ...rawProjectConfig.localTimeInput,
  formatLocalTime: makeFormatLocalTimeFn({ zone: localTimeInputZone, format }),
  dateTimeFromInputValue:
    rawProjectConfig.localTimeInput.inputType === 'date'
      ? makeDateTimeFromDateInputValueFn({ zone: localTimeInputZone })
      : makeDateTimeFromTimeInputValueFn({ zone: localTimeInputZone, defaultDate: defaultDate! }),
};

/// ///////////////

export type ProjectConfig = {
  projectId: 'jan6' | 'colombia' | 'us_police_violence';
  explorerTitle: string;
  defaultMapViewport: {
    latitude: number;
    longitude: number;
    zoom: number;
  };
  timeline: {
    start: Date;
    end: Date;
    tickIntervalHours: number;
  };
  timeFormat: {
    zone: string; // e.g. 'America/New_York'
    format: string; // e.g. 'h:mm a' see https://github.com/moment/luxon/blob/master/docs/formatting.md#table-of-tokens
  };
  defaultAnnotatorUrl: string;
  showSuspectFilter: boolean;
  // for the video annotator
  localTimeInput: LocalTimeInputConfig;
};

export const PROJECT_CONFIG: ProjectConfig = {
  ...rawProjectConfig,
  timeline: {
    ...rawProjectConfig.timeline,
    start: new Date(rawProjectConfig.timeline.start),
    end: new Date(rawProjectConfig.timeline.end),
  },
  localTimeInput: localTimeInputConfig,
};

type TopicTagType = {
  topicName: string;
  topicType: string;
};

const getTagList = (): Array<TopicTagType> => {
  switch (PROJECT_CONFIG.projectId) {
    case 'colombia':
      return colombiaTags;
    case 'us_police_violence':
      return usPoliceViolenceTags;
    case 'jan6':
      return jan6Tags;
    default:
      throw new Error(`Unexpected projectId=${PROJECT_CONFIG.projectId}`);
  }
};

export const tagList = getTagList();
