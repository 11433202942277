import React from 'react';

import { PROJECT_CONFIG } from '@jan6evidence/settings';

import { getStaticEvidence } from '../data';

import LoadState from '../types/LoadState';

import EvidenceApp from './EvidenceApp';
import MobileOverlay from './EvidenceMain/EvidenceMobileOverlay';
import FixedLayout from './FixedLayout';
import PageHeaderAndNav from './PageHeaderAndNav';

const StaticEvidencePage = () => {
  return (
    <div>
      <MobileOverlay />
      <PageHeaderAndNav projectConfig={PROJECT_CONFIG} />
      <FixedLayout>
        <EvidenceApp
          evidence={getStaticEvidence(
            PROJECT_CONFIG.timeline.start.getTime(),
            PROJECT_CONFIG.timeline.end.getTime()
          )}
          loadState={LoadState.LOADED}
          projectConfig={PROJECT_CONFIG}
        />
      </FixedLayout>
    </div>
  );
};

export default StaticEvidencePage;
