import React, { ReactElement } from 'react';
import 'react-virtualized/styles.css';

import { useHistory, useLocation } from 'react-router-dom';
import { Column, Table, AutoSizer } from 'react-virtualized';

import { TimeFormatterConfig } from '@jan6evidence/formatters';
import type { Evidence } from '@jan6evidence/types';

import { ResultLocation, ResultSummary, ResultTags, ResultThumbnail, ResultTime } from './Result';

import { TableWrapper, NoResults } from './evidence-results-styled-components';

type Props = {
  results: Evidence[];
  highlight: string;
  onHover: (id: string) => void;
  getDetailPath: (id: string) => string;
  timeFormatterConfig: TimeFormatterConfig;
};

function cellDataGetter({ rowData }: { rowData: any }): any {
  // Be default, react-virtualized tries to pass the cell's render
  // function just the data for that cell. To make our cell renderers
  // simpler, we pass the full row of data (so we can easily have cells
  // that show multiple fields of data)
  return rowData;
}

function makeCellRenderer(
  Component: React.ComponentType<{ cellData: Evidence }>
): ({ cellData }: { cellData?: Evidence }) => ReactElement {
  return function inner({ cellData }) {
    if (!cellData) {
      return <span />;
    }

    return <Component cellData={cellData} />;
  };
}

const ROW_HEIGHT = 150;

const EvidenceTable = ({
  results,
  highlight,
  onHover,
  getDetailPath,
  timeFormatterConfig,
}: Props) => {
  const history = useHistory();
  const location = useLocation();
  const handleOnClick = React.useCallback(
    ({ index }) => {
      history.push({ pathname: getDetailPath(results[index]._id), search: location.search });
    },
    [history, location, results]
  );

  return (
    <TableWrapper>
      <AutoSizer>
        {({ height, width }) => (
          <Table
            height={height}
            width={width}
            headerHeight={40}
            rowHeight={ROW_HEIGHT}
            rowCount={results.length}
            rowGetter={({ index }) => results[index]}
            rowClassName={({ index }) =>
              `table-row ${results[index]?._id === highlight ? 'highlighted' : ''}`
            }
            onRowClick={handleOnClick}
            onRowMouseOver={({ index }) => onHover(results[index]?._id)}
            onRowMouseOut={() => onHover('')}
            noRowsRenderer={() => <NoResults>No results found.</NoResults>}
          >
            <Column
              label="Thumbnail"
              dataKey="thumbnail"
              cellDataGetter={cellDataGetter}
              cellRenderer={makeCellRenderer(ResultThumbnail)}
              width={180}
            />
            <Column
              label="Summary"
              dataKey="summary"
              cellDataGetter={cellDataGetter}
              cellRenderer={makeCellRenderer(ResultSummary)}
              width={360}
              flexGrow={1}
            />
            <Column
              label="Tags"
              dataKey="tags"
              cellDataGetter={cellDataGetter}
              cellRenderer={makeCellRenderer(ResultTags)}
              width={100}
            />
            <Column
              label="Time"
              dataKey="time"
              cellDataGetter={cellDataGetter}
              cellRenderer={({ cellData }) => {
                if (!cellData) {
                  return <span />;
                }
                return <ResultTime cellData={cellData} timeFormatterConfig={timeFormatterConfig} />;
              }}
              width={80}
            />
            <Column
              label="Location"
              dataKey="location"
              cellDataGetter={cellDataGetter}
              cellRenderer={makeCellRenderer(ResultLocation)}
              width={144}
            />
          </Table>
        )}
      </AutoSizer>
    </TableWrapper>
  );
};

export default EvidenceTable;
