import React from 'react';
import Button from 'react-bootstrap/Button';
import ReactMarkdown from 'react-markdown';
import Modal from 'react-modal';
import { useHistory, useLocation } from 'react-router-dom';

import {
  localTimeRangeFormatter,
  TimeFormatterConfig,
  videoTimepointRangeFormatter,
} from '@jan6evidence/formatters';
import type { Evidence } from '@jan6evidence/types';

import { LocationLink } from '../shared/LocationLink';
import Tag from '../shared/Tag';
import { colors } from '../shared/constants';

import { ModalWrapper, Header, Content, Buttons, Nav } from './detail-styled-components';
import evidenceUpdateFormUrl from './evidenceFormUrl';
import { embedImage, embedVideo } from './media-embeds';

type Props = {
  detailId?: string;
  evidence: Evidence;
  onClose: () => void;
  prevPath: string;
  nextPath: string;
  collectionsFC?: (props: { id?: string; location?: string }) => React.ReactNode;
  timeFormatterConfig: TimeFormatterConfig;
};

const videoTimepointRow = (evidence: Evidence) => {
  if (!evidence.videoUrl) {
    return undefined;
  }
  const formattedRange = videoTimepointRangeFormatter(
    evidence.videoStartTimepoint,
    evidence.videoEndTimepoint
  );
  if (formattedRange) {
    return (
      <tr>
        <th scope="row">Video timepoint</th>
        <td>{formattedRange}</td>
      </tr>
    );
  } else {
    return undefined;
  }
};

export default function EvidenceDetail({
  detailId,
  evidence,
  onClose,
  prevPath,
  nextPath,
  // collectionsFC,
  timeFormatterConfig,
}: Props) {
  const history = useHistory();
  const location = useLocation();
  const handleOnClick = React.useCallback(
    (pathname: string) => {
      if (pathname) {
        history.push({ pathname, search: location.search });
      }
    },
    [history, location]
  );

  return (
    <Modal
      isOpen={!!detailId}
      onRequestClose={onClose}
      contentLabel="Evidence Details"
      style={{ overlay: { zIndex: 1000 } }}
      className="evidence-detail-modal"
      shouldCloseOnOverlayClick
    >
      {evidence ? (
        <ModalWrapper>
          <Header>
            <h2>Evidence Detail</h2>
            {/* // Hide collections for now
              <Buttons>
              {collectionsFC && collectionsFC({ id: evidence._id })}
              <button title="Close" type="button" className="close-button" onClick={onClose}>
                &times;
              </button>
            </Buttons> */}
          </Header>
          <Content>
            {evidence.imageUrl && (
              <div className="media">
                <a href={evidence.imageUrl}>
                  {embedImage(evidence)}
                  <span>{evidence.imageUrl}</span>
                </a>
              </div>
            )}
            {evidence.videoUrl && (
              <div className="media">
                {embedVideo(evidence)}
                <a href={evidence.videoUrl} target="_blank" rel="noreferrer">
                  {evidence.videoUrl}
                </a>
              </div>
            )}
            <table>
              <tbody>
                <tr>
                  <th scope="row"> </th>
                  <td>
                    <Button
                      href={evidenceUpdateFormUrl(evidence)}
                      target="_blank"
                      size="sm"
                      variant="outline-success"
                    >
                      Update Evidence
                    </Button>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Summary</th>
                  <td>
                    <ReactMarkdown>{evidence.summary || ''}</ReactMarkdown>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Tags</th>
                  <td>
                    {evidence.suspectTags &&
                      evidence.suspectTags.map((label) => (
                        <Tag
                          key={`${evidence._id}-person-${label}`}
                          id={`${evidence._id}-person-${label}`}
                          label={label}
                          color={colors.person}
                          title={label}
                        />
                      ))}
                    {evidence.otherTags &&
                      evidence.otherTags.map((label) => (
                        <Tag
                          key={`${evidence._id}-other-${label}`}
                          id={`${evidence._id}-other-${label}`}
                          label={label}
                          title={label}
                        />
                      ))}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Time</th>
                  <td>
                    {localTimeRangeFormatter(
                      {
                        start: evidence.realTimeStart,
                        end: evidence.realTimeEnd,
                      },
                      timeFormatterConfig
                    )}
                  </td>
                </tr>
                {videoTimepointRow(evidence)}
                <tr>
                  <th scope="row">Location</th>
                  <td>
                    <LocationLink latLng={evidence.location} name={evidence.locationName} />
                  </td>
                </tr>
                <tr>
                  <th scope="row">Rationale</th>
                  <td>
                    <ReactMarkdown>{evidence.rationale || ''}</ReactMarkdown>
                  </td>
                </tr>
              </tbody>
            </table>
            <Nav>
              <button
                type="button"
                disabled={!prevPath}
                title="Previous"
                onClick={() => handleOnClick(prevPath)}
              >
                &laquo;
              </button>
              <button
                type="button"
                disabled={!nextPath}
                title="Next"
                onClick={() => handleOnClick(nextPath)}
              >
                &raquo;
              </button>
            </Nav>
          </Content>
        </ModalWrapper>
      ) : (
        <ModalWrapper>
          <Header>
            <h2>Evidence &apos;{detailId}&apos; not found</h2>
            <Buttons>
              <button title="Close" type="button" className="close-button" onClick={onClose}>
                &times;
              </button>
            </Buttons>
          </Header>
        </ModalWrapper>
      )}
    </Modal>
  );
}
