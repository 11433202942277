/* eslint-disable no-console */

import React, { FunctionComponent, useEffect, useState } from 'react';

import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { keywordFilterFromTextInput } from '@jan6evidence/filters';
import { Suspect, SuspectTag } from '@jan6evidence/types';

import LoadState from '../types/LoadState';

import { GalleryItem, GalleryItemsInventory } from './SuspectsApp';
import {
  OverviewContainerDiv,
  SuspectCardDiv,
  SuspectFilters,
  SuspectThumbnail,
  SuspectThumbnailImg,
} from './SuspectsGallery';

const SuspectProfileDiv = styled.div`
  display: flex;
  width: 40rem;
`;

const SuspectAttrDiv = styled.div`
  font-size: 0.8rem;
  padding-bottom: 0.8rem;
  // border-bottom: solid 1px #ccc;
  line-height: 1.5;

  display: flex;

  .label {
    flex: 0 0 10rem;
    display: inline-block;
    color: #aaa;
    text-align: right;
    padding-right: 0.8rem;
    whitespace: nowrap;
  }

  .content {
    flex: 1 0 0;
    width: 30rem;
    // overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;
  }
`;

const SuspectAttr: FunctionComponent<{ displayIf: any; label: string }> = ({
  displayIf,
  label,
  children,
}) => {
  if (displayIf) {
    return (
      <SuspectAttrDiv>
        <div className="label">{label}</div>
        <div className="content">{children}</div>
      </SuspectAttrDiv>
    );
  } else {
    return null;
  }
};

const galleryLinkTo = (filters: Partial<SuspectFilters> | undefined): string => {
  const components = [
    ['kw', filters?.keyword?.text],
    ['charged', filters?.hasBeenCharged],
    ['fbi', filters?.hasFbiIdentifier],
  ].filter(([_k, v]) => v) as [string, string][];
  if (components.length === 0) {
    return '/gallery';
  } else {
    const search = components.map(([k, v]: [string, string]) => `${k}=${v}`).join('&');
    return `/gallery?${search}`;
  }
};

const OtherTags = ({ tags }: { tags: SuspectTag[] }) => {
  if (tags.length === 0) {
    return null;
  }
  const tagsByType: { [k: string]: string[] } = tags.reduce(
    (acc: { [k: string]: string[] }, tag: SuspectTag) => {
      if (!(tag.type in acc)) {
        acc[tag.type] = [];
      }
      acc[tag.type].push(tag.name);
      return acc;
    },
    {}
  );

  return (
    <>
      {[...Object.keys(tagsByType)].sort().map((tagType: string) => (
        <SuspectAttr displayIf label={`${tagType[0].toUpperCase()}${tagType.slice(1)} tags`}>
          {tagsByType[tagType].map((t: string) => (
            <>
              <Link to={galleryLinkTo({ keyword: keywordFilterFromTextInput(t) })}>#{t}</Link>{' '}
            </>
          ))}
        </SuspectAttr>
      ))}
    </>
  );
};

const ArrestedAttrs = ({
  suspect,
  dateFormatter,
}: {
  suspect: Suspect;
  dateFormatter: (d: Date) => string;
}) => {
  if (!suspect.chargingDocUrl) {
    return null;
  }
  const msg = [
    suspect.caseIdentifier ? `Case ${suspect.caseIdentifier}` : undefined,
    suspect.arrestDate ? dateFormatter(suspect.arrestDate) : undefined,
    suspect.arrestState,
  ]
    .filter((str) => str)
    .join(', ');

  return (
    <>
      <SuspectAttr displayIf label="Arrest docs">
        <a href={suspect.chargingDocUrl}>
          {suspect.caseIdentifier ? `Case ${suspect.caseIdentifier}` : 'Arrest docs'}
        </a>
      </SuspectAttr>
      <SuspectAttr displayIf={suspect.arrestDate} label="Arrested">
        {dateFormatter(suspect.arrestDate!)}
        {suspect.arrestState ? ` in ${suspect.arrestState}` : null}
      </SuspectAttr>
      <SuspectAttr displayIf={suspect.realName} label="Arrest Name">
        {suspect.realName}
      </SuspectAttr>
    </>
  );
};

const OtherSuspectLink = ({ hashtag }: { hashtag: string }) => (
  <Link to={`/gallery/${hashtag}`}>#{hashtag}</Link>
);

const SuspectProfile = ({
  suspect,
  dateFormatter,
}: {
  suspect: Suspect;
  dateFormatter: (d: Date) => string;
}) => {
  return (
    <SuspectProfileDiv>
      <SuspectThumbnail primaryHashtag={suspect.primaryHashtag} />
      <div style={{ marginLeft: '2rem' }}>
        <SuspectAttr displayIf={suspect.alternateHashtags.length > 0} label="Alt. hashtags">
          {suspect.alternateHashtags.map((t) => `#${t}`).join(', ')}
        </SuspectAttr>
        <SuspectAttr displayIf={suspect.fbiIdentifier} label="FBI Identifier">
          {suspect.fbiIdentifier}
        </SuspectAttr>
        <SuspectAttr displayIf={suspect.isMpdSuspect} label="MPD Person of Interest?">
          Yes
        </SuspectAttr>
        <SuspectAttr displayIf={suspect.caseStatus} label="Case Status">
          {suspect.caseStatus}
        </SuspectAttr>
        <ArrestedAttrs suspect={suspect} dateFormatter={dateFormatter} />
        <SuspectAttr displayIf={suspect.idStatus} label="ID Status">
          {suspect.idStatus}
        </SuspectAttr>
        <SuspectAttr displayIf={suspect.actionsSummary} label="Observed Actions">
          {suspect.actionsSummary}
        </SuspectAttr>
        <SuspectAttr displayIf={suspect.appearanceSummary} label="Appearance Notes">
          {suspect.appearanceSummary}
        </SuspectAttr>
        <SuspectAttr displayIf={suspect.associates.length > 0} label="Associates">
          {suspect.associates.map((t: string) => (
            <>
              <OtherSuspectLink hashtag={t} />{' '}
            </>
          ))}
        </SuspectAttr>
        <OtherTags tags={suspect.otherTags} />
      </div>
    </SuspectProfileDiv>
  );
};

const SuspectDetailGallery = ({ galleryItems }: { galleryItems: GalleryItem[] }) => (
  <OverviewContainerDiv>
    {galleryItems.map((gItem) => (
      <SuspectCardDiv data-title={`mediaItem-${gItem.mediaItemId}`}>
        <a href={gItem.archiveUrl} target="_blank" rel="noreferrer">
          <SuspectThumbnailImg
            src={gItem.thumbnailUrl || gItem.archiveUrl}
            alt={`thumbnail of media item ${gItem.mediaItemId}`}
          />
        </a>
      </SuspectCardDiv>
    ))}
  </OverviewContainerDiv>
);

const SuspectDetail = ({
  suspects,
  loadState,
  loadError,
  dateFormatter,
  prevSuspectFilters,
  detailItemsInventory,
}: {
  suspects: Suspect[];
  loadState: LoadState;
  loadError?: string;
  dateFormatter: (d: Date) => string;
  prevSuspectFilters: SuspectFilters | undefined;
  detailItemsInventory: GalleryItemsInventory;
}) => {
  const { suspectTag } = useParams() as { suspectTag: string };

  const suspect =
    suspects.find((s) => s.primaryHashtag?.toLocaleLowerCase() === suspectTag.toLowerCase()) ||
    undefined;

  return (
    <div>
      <div style={{ margin: '30px 30px 0px', maxWidth: '800px' }}>
        <p style={{ fontSize: '0.9rem', marginTop: '0px' }}>
          <Link to={galleryLinkTo(prevSuspectFilters)}>Back to the gallery</Link>
        </p>
        <h2>#{suspect ? suspect.primaryHashtag : suspectTag}</h2>
        {loadState === LoadState.LOADING ? <div>Loading...</div> : null}
        {loadState === LoadState.ERROR ? <div>Error: {loadError}</div> : null}
        {suspect ? (
          <SuspectProfile suspect={suspect} dateFormatter={dateFormatter} />
        ) : (
          <p>#{suspectTag} not found</p>
        )}
      </div>
      {suspect ? (
        <SuspectDetailGallery
          galleryItems={detailItemsInventory[suspect.primaryHashtag || ''] || []}
        />
      ) : null}
    </div>
  );
};

export default SuspectDetail;
