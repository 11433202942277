import type { LngLatBounds } from 'mapbox-gl';
import React from 'react';

import styled from 'styled-components';

import { localTimeRangeFormatter } from '@jan6evidence/formatters';
import { ProjectConfig } from '@jan6evidence/settings';
import { Evidence } from '@jan6evidence/types';

import { TagFilters, Toggles } from '../../types/TagFilters';
import PageHeaderAndNav from '../PageHeaderAndNav';
import { AppWrapper } from '../table-styled-components';

import EvidenceCopyableExport from './EvidenceCopyableExport';

type Props = {
  evidence: Evidence[];
  tagFilters?: TagFilters;
  keywords?: string[];
  toggles: Toggles;
  startEndTimes?: [Date, Date];
  bounds?: LngLatBounds;
  setExportView: (exportView: boolean) => void;
  projectConfig: ProjectConfig;
};

export const maybetwoColumnRow = (condition: boolean, label: string, content: any) =>
  condition ? (
    <tr>
      <th scope="row">{label}</th>
      <td>{content}</td>
    </tr>
  ) : null;

export const ExportDiv = styled.div`
  width: 1100px;
  padding: 30px;
  margin-left: auto;
  margin-right: auto;
`;

export const ExportEvidenceTable = styled.table`
  margin-bottom: 2em;
  border-top: solid 1px #ccc;
  font-size: 0.9em;
  td,
  th {
    padding-top: 3px;
    vertical-align: top;
    text-overflow: ellipsis;
  }
  th {
    text-align: right;
    width: 200px;
    padding-right: 1em;
  }
  td {
    width: 800px;
  }
  p {
    padding: 0px;
    margin: 0px;
  }
`;

const EvidenceExportView = ({
  evidence,
  tagFilters,
  keywords,
  toggles,
  startEndTimes,
  //  setExportView,
  bounds,
  projectConfig,
}: Props) => {
  return (
    <AppWrapper>
      <PageHeaderAndNav projectConfig={projectConfig} />
      <ExportDiv>
        <h2>Export Parameters</h2>
        <ExportEvidenceTable style={{ borderTop: 'none' }}>
          {maybetwoColumnRow(true, 'Exported at', new Date().toISOString())}
          {maybetwoColumnRow(
            (tagFilters?.suspect?.size || 0) > 0,
            'Suspect tag filters',
            Array.from(tagFilters?.suspect || []).join(', ')
          )}
          {maybetwoColumnRow(
            (tagFilters?.otherTags?.size || 0) > 0,
            'Other tag filters',
            Array.from(tagFilters?.otherTags || []).join(', ')
          )}
          {maybetwoColumnRow(
            (keywords?.length || 0) > 0 || false,
            'Keywords',
            keywords ? keywords.join(',') : null
          )}
          {maybetwoColumnRow(
            !!startEndTimes,
            'Time bounds',
            startEndTimes
              ? localTimeRangeFormatter(
                  { start: startEndTimes[0], end: startEndTimes[1] },
                  projectConfig.timeFormat
                )
              : null
          )}
          {maybetwoColumnRow(
            !!bounds,
            'Latitude',
            bounds ? `${bounds.getSouth()} to ${bounds.getNorth()}` : null
          )}
          {maybetwoColumnRow(
            !!bounds,
            'Longitude',
            bounds ? `${bounds.getWest()} to ${bounds.getEast()}` : null
          )}
          {maybetwoColumnRow(
            !!toggles?.includeUnlocated,
            '',
            'Including evidence without location'
          )}
          {maybetwoColumnRow(
            !!toggles?.includeUntimestamped,
            '',
            'Including evidence without time'
          )}
        </ExportEvidenceTable>
        <h2>
          {evidence.length} bit{evidence.length === 1 ? '' : 's'} of evidence
        </h2>
        <EvidenceCopyableExport
          evidence={evidence}
          timeFormatterConfig={projectConfig.timeFormat}
        />
      </ExportDiv>
    </AppWrapper>
  );
};

export default EvidenceExportView;
