import React from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { PATHS } from '@jan6evidence/routes';

const AboutDiv = styled.div`
  max-width: 800px;
  padding: 30px;
  margin-left: auto;
  margin-right: auto;

  /* Fixes a safari-specific issue where the header gets squashed on longer pages
     See https://github.com/jan6evidence/static-website/pull/177 */
  min-height: -webkit-fill-available;

  * + h2 {
    margin-top: 2rem;
  }
`;

function AboutPage() {
  return (
    <AboutDiv>
      <h2>About</h2>
      <p>
        Since the January 6 attack on the Capitol, hundreds of volunteers have scrutinized photos
        and video of the event. They have reviewed thousands of hours of footage, created timelines,
        identified and tracked persons of interest, synchronized videos, and provided analysis.
      </p>
      <p>
        This site is an effort to help organize and visualize this crowdsourced information.
        Contributors include data scientists, academic researchers, software engineers, and many
        others. All are motivated by a desire to understand what happened on January 6 and why. Most
        wish to remain anonymous.
      </p>
      <p>
        If you find this site helpful in your own work, please credit &ldquo;crowdsourced
        investigations shared on <a href="/">Jan6evidence.com</a>.&rdquo;
      </p>

      <h2>Evidence</h2>
      <p>
        The evidence available in the <Link to={PATHS.evidence}>explorer</Link> currently comes from
        two sources:{' '}
        <a href="https://docs.google.com/spreadsheets/d/1x5h4BasakKqXdtLGrf-bZqVCZ_JEPPWvjKMzmhp5n4w">
          this google sheet
        </a>{' '}
        assembled by volunteers, and the{' '}
        <a href="https://projects.propublica.org/parler-capitol-videos/">
          ProPublica-hosted Parler videos
        </a>{' '}
        . We also use the{' '}
        <a href="https://docs.google.com/spreadsheets/d/18NzncPJ-5oaYrDfq_VnjaSTLyY_vKaoSTgc6lznl9GU">
          Capitol Suspects Database
        </a>{' '}
        to identify persons of interest, and gathered latitude/longitudes and timing metadata for
        Parler videos from <a href="https://jan6attack.com/videos.htm">Jan6Attack.com</a> and
        <a href="https://gist.github.com/kylemcdonald/8fdabd6526924012c1f5afe538d7dc09">
          @kcimc&apos;s dataset
        </a>
        .
      </p>
      <p>
        This site is in beta, and the current set is just a small fraction of the crowdsourced data
        assembled online. Volunteers are continuously updating the data available in the explorer,
        and we&rsquo;ll streamline the process in the future. Here&rsquo;s how you can help:
      </p>
      <ul>
        <li>
          <a href="https://docs.google.com/document/d/1A7bK0p2k0pV_WOut8x69I6xGyZ3Qw9t4GWxnsGNCAfQ/edit">
            Sign up to review videos and log evidence
          </a>
          .
        </li>
        <li>
          You can also use those same{' '}
          <a href="https://docs.google.com/document/d/1A7bK0p2k0pV_WOut8x69I6xGyZ3Qw9t4GWxnsGNCAfQ/edit">
            instructions
          </a>{' '}
          to send us evidence logs from other videos as well.
        </li>
        <li>
          When looking at a piece of evidence on this site, you&rsquo;ll see an{' '}
          <b>Update Evidence</b> button. Use it to suggest a specific update.
        </li>
      </ul>
      <p>
        The evidence dataset is available for{' '}
        <a href="/data/evidence.json" download="evidence.json">
          download in JSON format
        </a>
        . Please note that the schema of this file may change at any time. We recommend downloading
        a copy for yourself rather than automating retrieval from this site in a manner that assumes
        a specific data structure.
      </p>
      <h2>Thanks</h2>
      <p>This site builds on the work of many others. It would not be possible without...</p>
      <ul>
        <li>
          <a href="https://twitter.com/donk_enby">@donk_enby</a>, who provided the{' '}
          <a href="https://twitter.com/donk_enby/status/1360064918742007809">
            largest source of evidence
          </a>{' '}
          from the attack.
        </li>
        <li>
          The dedicated teams building the{' '}
          <a href="https://docs.google.com/spreadsheets/d/18NzncPJ-5oaYrDfq_VnjaSTLyY_vKaoSTgc6lznl9GU/edit#gid=0">
            Capitol Suspects Database
          </a>{' '}
          and <a href="https://jan6attack.com/">Jan6Attack.com</a>.
        </li>
        <li>
          <i>More coming soon&hellip;</i>
        </li>
      </ul>
      <h2>Contact</h2>
      <ul>
        <li>
          Twitter: <a href="https://twitter.com/jan6evidence">@jan6evidence</a>
        </li>
        <li>
          Email: <a href="mailto:jan6evidence@gmail.com">jan6evidence@gmail.com</a>
        </li>
      </ul>
    </AboutDiv>
  );
}

export default AboutPage;
