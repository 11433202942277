import React from 'react';

import { PROJECT_CONFIG } from '@jan6evidence/settings';

import OtherResources from './OtherResourcesPage';
import { PageHeaderAndNav } from './PageHeaderAndNav';

import { AppWrapper } from './table-styled-components';

function StaticOtherResourcesPage() {
  return (
    <AppWrapper>
      <PageHeaderAndNav projectConfig={PROJECT_CONFIG} />
      <OtherResources />
    </AppWrapper>
  );
}

export default StaticOtherResourcesPage;
