import React from 'react';
import Form from 'react-bootstrap/Form';

import { SectionHeader } from './filter-box-styled-components';

type Props = {
  onChange: (search: string) => void;
  value: string;
};

const KeywordSearch = ({ onChange, value }: Props) => {
  return (
    <>
      <SectionHeader>Keywords</SectionHeader>
      <Form.Control
        type="text"
        placeholder="Search"
        onChange={(e) => onChange(e.target.value)}
        value={value}
        size="sm"
      />
    </>
  );
};

export default KeywordSearch;
