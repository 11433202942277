import React from 'react';

import { HandleShape } from './evidence-results-styled-components';

export type Range = [number, number];

export interface RangePoint {
  id: string;
  value: number;
  percent: number;
}

export const RAIL_HEIGHT = 48;

// *******************************************************
// RAIL
// *******************************************************
const railOuterStyle: React.CSSProperties = {
  position: 'absolute',
  width: '100%',
  height: RAIL_HEIGHT,
  cursor: 'pointer',
};

const railInnerStyle: React.CSSProperties = {
  position: 'absolute',
  width: '100%',
  height: RAIL_HEIGHT,
  pointerEvents: 'none',
  backgroundColor: '#555',
};

type SliderRailProps = {
  getRailProps: () => void;
};

export function SliderRail({ getRailProps }: SliderRailProps) {
  return (
    <>
      <div style={railOuterStyle} {...getRailProps()} />
      <div style={railInnerStyle} />
    </>
  );
}

// *******************************************************
// HANDLE COMPONENT
// *******************************************************
type HandleProps = {
  domain: Range;
  handle: RangePoint;
  getHandleProps: (id: string) => void;
  disabled?: boolean;
  isRight?: boolean;
};

export function Handle({
  domain: [min, max],
  handle: { id, value, percent },
  disabled,
  isRight,
  getHandleProps,
}: HandleProps) {
  return (
    <>
      <div
        style={{
          left: `${percent}%`,
          position: 'absolute',
          transform: 'translate(-50%, 0)',
          WebkitTapHighlightColor: 'rgba(0,0,0,0)',
          zIndex: 5,
          width: 28,
          height: 42,
          cursor: 'move',
          backgroundColor: 'none',
        }}
        {...getHandleProps(id)}
      />
      <div
        role="slider"
        aria-label={`Handle at ${value}`}
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        style={{
          left: `${percent}%`,
          position: 'absolute',
          marginTop: -3,
          transform: 'translate(-50%, -0)',
          zIndex: 3,
          width: 12,
          height: RAIL_HEIGHT,
        }}
      >
        <HandleShape
          viewBox={`0 0 12 ${RAIL_HEIGHT + 6}`}
          className={`handle ${id} ${disabled && 'disabled'}`}
        >
          {isRight ? (
            <polygon
              points={`0,0 12,0 12,${RAIL_HEIGHT + 6} 0,${
                RAIL_HEIGHT + 6
              } 0,${RAIL_HEIGHT} 6,${RAIL_HEIGHT} 6,6 0,6`}
            />
          ) : (
            <polygon
              points={`0,0 0,${RAIL_HEIGHT + 6} 12,${
                RAIL_HEIGHT + 6
              } 12,${RAIL_HEIGHT} 6,${RAIL_HEIGHT} 6,6 12,6 12,0`}
            />
          )}
        </HandleShape>
      </div>
    </>
  );
}

// *******************************************************
// KEYBOARD HANDLE COMPONENT
// Uses a button to allow keyboard events
// *******************************************************
export function KeyboardHandle({
  domain: [min, max],
  handle: { id, value, percent },
  disabled,
  getHandleProps,
}: HandleProps) {
  return (
    <button
      type="button"
      role="slider"
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      style={{
        left: `${percent}%`,
        position: 'absolute',
        transform: 'translate(-50%, 0)',
        zIndex: 2,
        width: 24,
        height: RAIL_HEIGHT,
        borderRadius: '50%',
        boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.3)',
        backgroundColor: disabled ? '#999' : '#ffc400',
      }}
      {...getHandleProps(id)}
    />
  );
}

// *******************************************************
// TRACK COMPONENT
// *******************************************************
type TrackProps = {
  source: RangePoint;
  target: RangePoint;
  getTrackProps: () => void;
  disabled?: boolean;
};

export function Track({ source, target, getTrackProps, disabled }: TrackProps) {
  return (
    <div
      style={{
        position: 'absolute',
        height: RAIL_HEIGHT,
        zIndex: 1,
        backgroundColor: disabled ? '#555' : '#222',
        borderRadius: 7,
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {...getTrackProps()}
    />
  );
}

// *******************************************************
// TICK COMPONENT
// *******************************************************
type TickType = {
  tick: RangePoint;
  count: number;
  formatterFn: (value: number | undefined) => string | undefined;
};

export function Tick({ tick, formatterFn }: TickType) {
  return (
    <div
      style={{
        position: 'absolute',
        left: `${tick.percent}%`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        transform: 'translate(-50%, 0px)',
      }}
    >
      <div
        className="tick"
        style={{
          marginTop: RAIL_HEIGHT,
          width: 1,
          height: 6,
        }}
      />
      <div
        style={{
          fontSize: 10,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {formatterFn(tick.value)}
      </div>
    </div>
  );
}
