import styled from 'styled-components';

import { colors, shadows, padding, widths } from '../shared/constants';
import { TextButton, LeftBox } from '../shared/mixins';

export const Box = styled.div`
  ${LeftBox}

  font-size: 12px;
  line-height: 18px;

  h4 {
    font-size: 18px;
    line-height: 24px;
    padding-left: ${padding.sm}px;
  }

  h5 {
    font-size: 15px;
    line-height: 24px;
  }

  .btn-group-toggle {
    flex: 1 1 auto;
    max-height: ${(props: any) => props.btnGroupToggleMaxHeight || '25vh'};
    overflow-y: auto;
    overflow-x: hidden;
    border-top: 1px solid ${colors.tableLines};
    border-bottom: 1px solid ${colors.tableLines};

    display: block;

    > .btn {
      font-size: 12px;
      line-height: 12px;
      text-align: left;
      padding: ${padding.sm}px;
      border-radius: 0;

      color: black;
      background-color: white;
      border-color: ${colors.tableLines};

      display: flex;
      flex-direction: row;

      &:hover {
        background-color: #bbb;
      }

      &.active {
        color: black;
        background-color: ${colors.activeFilter};

        &:hover {
          background-color: ${colors.activeFilterHover};
        }
      }

      &:first-child {
        border-top: 0;
      }

      &.hidden {
        display: none;
      }

      .label {
        flex: 1;
      }

      .count {
        color: gray;
        font-style: italic;
        width: 25px;
      }
    }
  }

  .choice-filter {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    z-index: 1;
    border-bottom: 0;
    border-color: ${colors.tableLines};
    width: calc(100% + 1px);
  }
`;

export const BoxHeader = styled.div`
  display: flex;
  align-items: stretch;
  margin-bottom: ${padding.md}px;

  h4 {
    flex: 1 1 auto;
    margin-bottom: 0;
  }

  button {
    ${TextButton}
  }
`;

export const Toggles = styled.div`
  padding: ${padding.md}px ${padding.sm}px ${padding.sm}px ${padding.sm}px;
`;

export const SectionHeader = styled.h5`
  flex: 0 0 auto;
  width: 100%;
  text-align: left;

  padding: ${padding.sm}px;
  padding-bottom: 0;

  display: flex;
  align-items: center;
`;

export const FilterPills = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-left: ${padding.sm}px;

  .time {
    background: #beb;
  }
  .location {
    background: white;
  }
  .suspect {
    background: ${colors.person};
  }
  .type {
    background: #bbe;
  }
`;
