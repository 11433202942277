import React from 'react';

import { SectionHeader } from './filter-box-styled-components';

type Props = {
  label?: string;
  children: React.ReactNode;
};

const Section = ({ children, label }: Props) => {
  return (
    <>
      {label && <SectionHeader>{label}</SectionHeader>}
      {children}
    </>
  );
};

export default Section;
