import React, { ReactElement } from 'react';
import 'react-virtualized/styles.css';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
  localTimeRangeFormatter,
  TimeFormatterConfig,
  videoTimepointRangeFormatter,
} from '@jan6evidence/formatters';
import { PATHS } from '@jan6evidence/routes';
import type { Evidence } from '@jan6evidence/types';

import LocationLink from '../shared/LocationLink';

import { ExportEvidenceTable, maybetwoColumnRow } from './EvidenceExportView';

const ExportEvidenceItem = ({
  item,
  timeFormatterConfig,
}: {
  item: Evidence;
  timeFormatterConfig: TimeFormatterConfig;
}) => {
  const detailUrl = `${PATHS.evidence}/${item._id}`;
  const allTags = (item.suspectTags || []).concat(item.otherTags || []);
  return (
    <ExportEvidenceTable>
      {maybetwoColumnRow(true, 'Evidence ID', <Link to={detailUrl}>{item._id}</Link>)}
      {maybetwoColumnRow(!!item.imageUrl, 'Image URL', <a href={item.imageUrl}>{item.imageUrl}</a>)}
      {maybetwoColumnRow(!!item.videoUrl, 'Video URL', <a href={item.videoUrl}>{item.videoUrl}</a>)}
      {maybetwoColumnRow(
        item.videoStartTimepoint !== undefined,
        'Video timepoint',
        videoTimepointRangeFormatter(item.videoStartTimepoint, item.videoEndTimepoint)
      )}
      {maybetwoColumnRow(
        !!item.realTimeStart,
        'Local time',
        localTimeRangeFormatter(
          { start: item.realTimeStart, end: item.realTimeEnd },
          timeFormatterConfig
        )
      )}
      {maybetwoColumnRow(
        !!(item.location || item.locationName),
        'Location',
        <LocationLink latLng={item.location} name={item.locationName} />
      )}
      {maybetwoColumnRow(allTags.length > 0, 'Tags', allTags.join(', '))}
      {maybetwoColumnRow(
        !!item.summary,
        'Summary',
        <ReactMarkdown>{item.summary || ''}</ReactMarkdown>
      )}
      {maybetwoColumnRow(
        !!item.rationale,
        'Rationale',
        <ReactMarkdown>{item.rationale || ''}</ReactMarkdown>
      )}
    </ExportEvidenceTable>
  );
};

const EvidenceCopyableExport = ({
  evidence,
  timeFormatterConfig,
}: {
  evidence: Evidence[];
  timeFormatterConfig: TimeFormatterConfig;
}) => {
  return (
    <div>
      {evidence.map((ev) => (
        <ExportEvidenceItem item={ev} timeFormatterConfig={timeFormatterConfig} />
      ))}
    </div>
  );
};

export default EvidenceCopyableExport;
