import React from 'react';
import { Tweet } from 'react-twitter-widgets';

import { Evidence, VideoDetailsArchiveOrg } from '@jan6evidence/types';

const safeMakeUrl = (urlAsString: string | undefined): URL | undefined => {
  try {
    return new URL(urlAsString || '');
  } catch {
    return undefined;
  }
};

const tweetIdFromUrl = (urlAsString: string | undefined): string | undefined => {
  const url = new URL(urlAsString || '');
  if (!url.hostname.match(/twitter\.com$/)) {
    return undefined;
  }
  const matchResult = url.pathname.match(/status\/(\d+)/);
  return (matchResult && matchResult[1]) || undefined;
};

const youtubeIframe = (evidence: Evidence, ytVideoId: string) => {
  const url = new URL(`https://www.youtube.com/embed/${ytVideoId}`);
  if (evidence.videoStartTimepoint) {
    url.searchParams.append('start', evidence.videoStartTimepoint.toString());
  }
  return (
    <iframe
      key={evidence.videoUrl}
      title="embedded evidence video from youtube"
      width="560"
      height="315"
      src={url.href}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
};

const archiveOrgVideoIframe = (evidence: Evidence) => {
  const videoDetails = evidence.videoDetails as VideoDetailsArchiveOrg;
  const url = new URL(
    `https://archive.org/embed/${videoDetails.identifier}/${videoDetails.filename}`
  );
  if (evidence.videoStartTimepoint) {
    url.searchParams.append('start', evidence.videoStartTimepoint.toString());
  }
  return (
    <iframe
      key={evidence.videoUrl}
      title="embedded evidence video from archive.org"
      src={url.href}
      width="640"
      height="480"
      frameBorder="0"
      allowFullScreen
    />
  );
};

const bareMp4VideoTag = (evidence: Evidence) => {
  const url = safeMakeUrl(evidence.videoUrl);
  if (!url) {
    return null;
  }
  if (evidence.videoStartTimepoint && (url.hash || '').trim() === '') {
    url.hash = `t=${evidence.videoStartTimepoint}`;
  }
  return (
    <video controls key={evidence.videoUrl}>
      <source src={url.href} />
    </video>
  );
};

const vimeoIframe = (evidence: Evidence, vimeoId: string) => {
  const url = new URL(`https://player.vimeo.com/video/${vimeoId}`);
  if (evidence.videoStartTimepoint) {
    url.hash = `t=${evidence.videoStartTimepoint}s`;
  }
  return (
    <iframe
      key={evidence.videoUrl}
      title="embedded evidence video from vimeo.com"
      src={url.href}
      width="640"
      height="480"
      frameBorder="0"
      allowFullScreen
    />
  );
};

const facebookIframe = (evidence: Evidence, profileId: string, videoId: string) => {
  const videoUrl = new URL(`https://facebook.com/${profileId}/videos/${videoId}`);
  // start time doesn't seem to be respected...
  if (evidence.videoStartTimepoint) {
    videoUrl.searchParams.append('t', `${evidence.videoStartTimepoint}s`);
  }
  const embedUrl = new URL('https://www.facebook.com/plugins/video.php');
  embedUrl.searchParams.append('href', videoUrl.href);
  embedUrl.searchParams.append('show_text', 'false');
  return (
    <iframe
      key={evidence.videoUrl}
      title="embedded evidence video from facebook.com"
      src={embedUrl.href}
      width="476"
      height="476"
      style={{ border: 'none', overflow: 'hidden' }}
      scrolling="no"
      frameBorder="0"
      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
      allowFullScreen
    />
  );
};

const megaVideoIframe = (evidence: Evidence, megaVideoId: string) => {
  let urlString = `https://mega.nz/embed/${megaVideoId}`;
  if (evidence.videoStartTimepoint) {
    urlString += `!${evidence.videoStartTimepoint}s`;
  }
  return (
    <iframe
      key={evidence.videoUrl}
      title="embedded evidence video from mega.nz"
      width="560"
      height="315"
      src={urlString}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
};

const googleDriveIframe = (_evidence: Evidence, driveFileId: string) => {
  const urlString = `https://drive.google.com/file/d/${driveFileId}/preview`;
  // adding t=${startTimeInSeconds} seems to start the video in the right place
  // but results in the video not playing correctly, so skip for now
  return (
    <iframe
      title="embedded evidence video from google drive"
      src={urlString}
      width="640"
      height="480"
      frameBorder="0"
      allowFullScreen
    />
  );
};

const rumbleIframe = (_evidence: Evidence, embedId: string) => {
  const urlString = `https://rumble.com/embed/${embedId}`;
  return (
    <iframe
      title="embedded evidence video from rumble"
      src={urlString}
      width="640"
      height="360"
      frameBorder="0"
      allowFullScreen
    />
  );
};

const instagramIframe = (_evidence: Evidence, videoId: string) => {
  const urlString = `https://www.instagram.com/tv/${videoId}/embed`;
  return (
    <iframe
      title="embedded evidence video from rumble"
      src={urlString}
      width="400"
      height="480"
      frameBorder="0"
      scrolling="no"
      // width="640"
      // height="360"
      // frameBorder="0"
      // allowFullScreen
    />
  );
};

const embedTweet = (tweetId: string) => {
  return <Tweet tweetId={tweetId} options={{ conversation: 'none' }} />;
};

export const embedVideo = (evidence: Evidence) => {
  if (!evidence.videoDetails) {
    return null;
  }

  switch (evidence.videoDetails.type) {
    case 'youtube':
      return youtubeIframe(evidence, evidence.videoDetails.videoId);
    case 'archiveOrg':
      return archiveOrgVideoIframe(evidence);
    case 'tweet':
      return embedTweet(evidence.videoDetails.tweetId);
    case 'megavideo':
      return megaVideoIframe(evidence, evidence.videoDetails.videoId);
    case 'vimeo':
      return vimeoIframe(evidence, evidence.videoDetails.videoId);
    case 'facebook':
      return facebookIframe(
        evidence,
        evidence.videoDetails.profileId,
        evidence.videoDetails.videoId
      );
    case 'googleDrive':
      return googleDriveIframe(evidence, evidence.videoDetails.driveFileId);
    case 'rumble':
      return rumbleIframe(evidence, evidence.videoDetails.embedId || '');
    case 'directLink':
      return bareMp4VideoTag(evidence);
    case 'instagram':
      return instagramIframe(evidence, evidence.videoDetails.videoId);
    default:
      return null;
  }
};

const pacificPressIdFromUrl = (urlAsString: string | undefined): string | undefined => {
  const url = new URL(urlAsString || '');
  if (!url.hostname.match(/(^|\.)pacificpressagency\.com$/)) {
    return undefined;
  }
  const matchResult = url.pathname.match(/preview\/(\d+)/);
  return (matchResult && matchResult[1]) || undefined;
};

const embedPacificPressImage = (ppId: string) => {
  return (
    <img
      src={`https://www.pacificpressagency.com/assetpreview/get/${ppId}`}
      alt="embedded evidence from Pacific Press Agency"
    />
  );
};

export const embedImage = (evidence: Evidence) => {
  if (!evidence.imageUrl) {
    return null;
  }

  const tweetId = tweetIdFromUrl(evidence.imageUrl);
  if (tweetId) {
    return embedTweet(tweetId);
  }

  const pacificPressId = pacificPressIdFromUrl(evidence.imageUrl);
  if (pacificPressId) {
    return embedPacificPressImage(pacificPressId);
  }

  return (
    <img
      src={evidence.imageUrl}
      alt={evidence.summary || evidence.locationName || 'Evidence Image'}
    />
  );
};
