/* eslint-disable no-console */

import React, { useEffect, useState } from 'react';

import { PROJECT_CONFIG } from '@jan6evidence/settings';
import { ArchiveForEnrichedMediaItem, EnrichedMediaItem } from '@jan6evidence/types';

import FixedLayout from './FixedLayout';
import MediaItemsOverview from './MediaItemsOverview';
import PageHeaderAndNav from './PageHeaderAndNav';
import { usePageTitle } from './shared/hooks';

enum LoadState {
  INIT = 'init',
  GET_SINGLE_USE_TOKEN = 'get_single_use_token',
  FETCH = 'fetch',
  LOADED = 'loaded',
  ERROR = 'error',
}

const toDate = (str: string | undefined): Date | undefined => (str ? new Date(str) : undefined);

export const getItemsFromRawData = (items: any[]): EnrichedMediaItem[] =>
  items.map((item) => {
    const sourceCreatedAt = toDate(item.sourceCreatedAt);
    const sourceUnavailableAt = toDate(item.sourceUnavailableAt);
    const archives = item.archives.map((a: any) => ({
      ...a,
      archivedAt: toDate(a.archivedAt),
    }));
    return {
      ...item,
      sourceCreatedAt,
      sourceUnavailableAt,
      archives,
    } as EnrichedMediaItem;
  });

const pathToEvidenceFromItem = (item: EnrichedMediaItem): string => `e?keywords=${item._id}`;

const StaticMediaItemsPage = () => {
  const [loadState, setLoadState] = useState<LoadState>(LoadState.INIT);
  const [loadError, setLoadError] = useState<string>('');
  const [data, setData] = useState<Array<EnrichedMediaItem>>([]);

  useEffect(() => {
    if (loadState === LoadState.INIT) {
      fetch('/data/enriched-media-items.json')
        .then((response) => response.text())
        .then((body) => {
          const enrichedItems = getItemsFromRawData(JSON.parse(body));
          setData(enrichedItems);
          setLoadState(LoadState.LOADED);
        })
        .catch((error) => {
          console.log(error.message);
          setLoadError(error.message);
          setLoadState(LoadState.ERROR);
        });
    }
  });

  usePageTitle('Media Items');

  return (
    <div>
      <PageHeaderAndNav projectConfig={PROJECT_CONFIG} />
      <FixedLayout>
        <MediaItemsOverview
          ready={loadState === LoadState.LOADED}
          items={data}
          annotationLinkPath={pathToEvidenceFromItem}
        />
      </FixedLayout>
    </div>
  );
};

export default StaticMediaItemsPage;
