import React from 'react';

import { suspects } from '@jan6evidence/data';
import { PROJECT_CONFIG } from '@jan6evidence/settings';

import LoadState from '../types/LoadState';

import PageHeaderAndNav from './PageHeaderAndNav';
import SuspectsApp from './SuspectsApp';
import { AppWrapper } from './table-styled-components';

const StaticSuspectsApp = () => {
  return (
    <AppWrapper>
      <PageHeaderAndNav projectConfig={PROJECT_CONFIG} />
      <SuspectsApp suspects={suspects} loadState={LoadState.LOADED} />
    </AppWrapper>
  );
};

export default StaticSuspectsApp;
