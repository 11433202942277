/* eslint-disable no-console */

import { URL } from 'url';

import * as nodeUrl from 'url';

declare let window: any;
let UrlConstructor: any;
if (typeof window !== 'undefined') {
  UrlConstructor = window.URL;
} else {
  UrlConstructor = nodeUrl.URL;
}

export const urlHasDomain = (url: URL, domain: string): boolean =>
  url.hostname === domain || url.hostname.endsWith(`.${domain}`);

export default function urlOrNull(
  urlAsString: string | undefined,
  opts?: { domain?: string; logErrors?: boolean }
): URL | null {
  let url;
  try {
    url = new UrlConstructor(urlAsString || '');
  } catch (e) {
    if (!e.message.match('Invalid URL')) {
      throw e;
    }
    if (opts?.logErrors) {
      console.error(e);
    }
    return null;
  }
  if (opts?.domain && !urlHasDomain(url, opts.domain)) {
    return null;
  } else {
    return url;
  }
}
