import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import { PATHS } from '@jan6evidence/routes';

import Error404Page from './Error404Page';
import StaticAboutPage from './StaticAboutPage';
import StaticEvidencePage from './StaticEvidencePage';
import StaticMediaItemsPage from './StaticMediaItemsPage';
import StaticOtherResourcesPage from './StaticOtherResourcesPage';
import StaticSuspectsApp from './StaticSuspectsApp';

function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={PATHS.evidence} />
      </Route>
      <Route path={PATHS.about} component={StaticAboutPage} />
      <Route path={PATHS.resources} component={StaticOtherResourcesPage} />
      <Route path={PATHS.suspects} component={StaticSuspectsApp} />
      <Route path={PATHS.evidence} exact component={StaticEvidencePage} />
      <Route path={`${PATHS.evidence}/:detailId`} component={StaticEvidencePage} />
      <Route path={PATHS.mediaItems} component={StaticMediaItemsPage} />
      <Route component={Error404Page} />
    </Switch>
  );
}

export default Routes;
