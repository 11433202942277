import React from 'react';
import Form from 'react-bootstrap/Form';
import { useHistory } from 'react-router-dom';

import type { TagFilters, TagFilterChoicesWithCount, Toggles } from '../../types/TagFilters';

import ActiveFilters from './ActiveFilters';

import KeywordSearch from './KeywordSearch';
import Section from './Section';
import TagFilterChoiceBox from './TagFilterChoiceBox';
import { Box, BoxHeader, Toggles as TogglesContainer } from './filter-box-styled-components';

type Props = {
  keywords: string[];
  tagFilters: TagFilters;
  toggles: Toggles;
  choices: TagFilterChoicesWithCount;
  onKeywordsChanged: (newKeywords: string[]) => void;
  onTagFilterChanged: <K extends keyof TagFilters>(key: K, newValue: TagFilters[K]) => void;
  onToggleChanged: <K extends keyof Toggles>(key: K, newValue: Toggles[K]) => void;
  showSuspectFilters: boolean;
};

const removeOneFromSection = (section: Set<string>, id: string) => {
  section.delete(id);
  return section;
};

const FilterBox = ({
  keywords,
  tagFilters,
  toggles,
  choices,
  onKeywordsChanged,
  onTagFilterChanged,
  onToggleChanged,
  showSuspectFilters,
}: Props) => {
  const history = useHistory();

  const [suspectTagChoicesFilter, setSuspectTagChoicesFilter] = React.useState<string>('');
  const [otherTagChoicesFilter, setOtherTagChoicesFilter] = React.useState<string>('');

  const clearAll = () => {
    setSuspectTagChoicesFilter('');
    setOtherTagChoicesFilter('');
    onTagFilterChanged('suspect', new Set());
    onTagFilterChanged('otherTags', new Set());
    onKeywordsChanged([]);

    // The above should be sufficient to clear filters, but because successive
    // query param updates are using stale query params, it will not clear
    // correctly. Need to access a fresh copy of `useLocation` in `setValue`,
    // but hooks cannot be calling inside.
    history.push({ search: '' });
  };

  return (
    // @ts-ignore
    <Box btnGroupToggleMaxHeight={showSuspectFilters ? '25vh' : '50vh'}>
      <BoxHeader>
        <h4>Filters</h4>
        <button type="button" onClick={clearAll}>
          Clear all
        </button>
      </BoxHeader>
      <ActiveFilters
        tagFilters={tagFilters}
        choices={choices}
        keywords={keywords}
        onDelete={(type: 'keywords' | keyof TagFilters, id) =>
          type === 'keywords'
            ? onKeywordsChanged([])
            : onTagFilterChanged(type, removeOneFromSection(tagFilters[type], id))
        }
      />
      <TogglesContainer>
        <Form.Switch
          type="switch"
          id="include-untimestamped"
          label="Include evidence without a time"
          checked={toggles.includeUntimestamped}
          onChange={(evt) => onToggleChanged('includeUntimestamped', evt.target.checked)}
        />
        <Form.Switch
          type="switch"
          id="include-unlocated"
          label="Include evidence without a location"
          checked={toggles.includeUnlocated}
          onChange={(evt) => onToggleChanged('includeUnlocated', evt.target.checked)}
        />
      </TogglesContainer>
      <KeywordSearch
        onChange={(search) => onKeywordsChanged(search.split(' '))}
        value={keywords.join(' ')}
      />

      {showSuspectFilters ? (
        <Section label="Suspects">
          <TagFilterChoiceBox
            categoryId="suspects"
            choices={choices.suspect}
            choicesFilter={suspectTagChoicesFilter}
            setChoicesFilter={setSuspectTagChoicesFilter}
            selectedIds={tagFilters.suspect}
            onChange={(params) => onTagFilterChanged('suspect', params)}
          />
        </Section>
      ) : null}
      <Section label={showSuspectFilters ? 'Other Tags' : 'Tags'}>
        <TagFilterChoiceBox
          categoryId="otherTags"
          choices={choices.otherTags}
          choicesFilter={otherTagChoicesFilter}
          setChoicesFilter={setOtherTagChoicesFilter}
          selectedIds={tagFilters.otherTags}
          onChange={(params) => onTagFilterChanged('otherTags', params)}
        />
      </Section>
    </Box>
  );
};

export default FilterBox;
