import { VideoDetails } from '@jan6evidence/types';

export type MediaUrlMatch<T extends VideoDetails> = {
  canonicalUrl: string;
  mediaType: 'video' | 'photo';
  videoDetails?: T;
};

export abstract class MediaUrlMatcher<T extends VideoDetails> {
  isMatch(url: string): boolean {
    return !!this.getIdsFromUrl(url);
  }

  // Returns a promise because sometimes getting the canonical match requires
  // fetching data from the URL.
  abstract makeCanonical(url: string): Promise<MediaUrlMatch<T> | undefined>;

  abstract getIdsFromUrl(url: string): any;
}
