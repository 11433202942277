import styled from 'styled-components';

import { padding, shadows } from './constants';
import { TextButton } from './mixins';

export const Pill = styled.span`
  position: relative;
  border-radius: ${padding.sm}px;
  background: #b0adaa;
  display: inline-block;
  padding: 1px 4px 1px 4px;
  margin: 0 3px 3px 0;
  font-size: 12px;

  box-shadow: ${shadows.sm};

  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.has-delete {
    padding-right: 16px;
  }
`;

export const PillXButton = styled.button`
  ${TextButton}
  height: 100%;
  padding-right: 4px;
  position: absolute;
  top: 0;
  right: 0;
`;
