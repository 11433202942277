import { DateTime } from 'luxon';

import type { Location as LatLng } from '../types/Location';

export type TimeFormatterConfig = {
  format: string; // e.g. 'h:mm a' see https://github.com/moment/luxon/blob/master/docs/formatting.md#table-of-tokens
  zone: string; // e.g. 'America/New_York'
};

export const localTimeFormatter = (
  d: Date | number | string | undefined,
  config: TimeFormatterConfig
): string | undefined => {
  const dateObj = typeof d === 'number' || typeof d === 'string' ? new Date(d) : d;
  if (dateObj) {
    return DateTime.fromJSDate(dateObj, { zone: config.zone }).toFormat(config.format);
  } else {
    return undefined;
  }
};

export const localTimeRangeFormatter = (
  range: { start?: Date | number; end?: Date | number },
  config: TimeFormatterConfig
): string => {
  const formattedRealTimeStart = localTimeFormatter(range.start, config) || '??:??';
  const formattedRealTimeEnd = range.end ? localTimeFormatter(range.end, config) : undefined;
  if (formattedRealTimeEnd && formattedRealTimeEnd !== formattedRealTimeStart) {
    return `${formattedRealTimeStart} - ${formattedRealTimeEnd}`;
  } else {
    return formattedRealTimeStart;
  }
};

export const videoTimepointFormatter = (timeSec?: number): string | undefined => {
  if (timeSec === undefined) {
    return undefined;
  }
  // https://stackoverflow.com/a/1322771
  // Note that this ignores decimals.
  const hhmmss = new Date(timeSec * 1000).toISOString().substr(11, 8);
  if (hhmmss.startsWith('00')) {
    return hhmmss.slice(1);
  } else {
    return hhmmss;
  }
};

export const videoTimepointRangeFormatter = (
  startSec?: number,
  endSec?: number
): string | undefined => {
  if (startSec === undefined) {
    return undefined;
  }
  const formattedStart = videoTimepointFormatter(startSec) || '??:??';
  const formattedEnd = endSec ? videoTimepointFormatter(endSec) : undefined;
  if (formattedEnd && formattedEnd !== formattedStart) {
    return `${formattedStart} - ${formattedEnd}`;
  } else {
    return formattedStart;
  }
};

export const toNdecimals = (x: number, places: number): string => {
  const pow10 = 10 ** places;
  const roundedX = Math.round(x * pow10) / pow10;
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: places,
    maximumFractionDigits: places,
  });
  return formatter.format(roundedX);
};

const LAT_LONG_DECIMAL_PLACES = 4;

export const latLngFormatter = (
  latLng: LatLng | undefined,
  decimalPlaces: number = LAT_LONG_DECIMAL_PLACES
): string | undefined => {
  if (latLng) {
    const [latStr, lngStr] = [latLng.lat, latLng.lng].map((x) => toNdecimals(x, decimalPlaces));
    return `${latStr}, ${lngStr}`;
  } else {
    return undefined;
  }
};
