export type TagDeclaration = {
  mainTag: string;
  alternateTags?: Array<string>;
};

export class CanonicalTags {
  private lookupTable: { [k: string]: string } = {};

  warnings: Array<string> = [];

  constructor(declarations: Array<TagDeclaration>) {
    // loop through main tags first
    declarations.forEach((dec) => this.addToStore(dec.mainTag, dec.mainTag));
    // then loop through again to add the alternates
    declarations.forEach((dec) =>
      (dec.alternateTags || []).forEach((altTag) => this.addToStore(altTag, dec.mainTag))
    );
  }

  logWarningsToConsole() {
    /* eslint-disable-next-line no-console */
    this.warnings.forEach((msg) => console.warn(msg));
  }

  lookup(key: string): string | undefined {
    const cleanedKey = this.cleanLookupKey(key);
    return this.lookupTable[cleanedKey];
  }

  allCanonicalTags(): Set<string> {
    return new Set(Object.values(this.lookupTable));
  }

  private addToStore(key: string, mainTag: string) {
    const currentValue = this.lookup(key);
    if (currentValue && currentValue === mainTag) {
      this.warnings.push(
        `Already stored ${currentValue} as canonical form of ${key}, ignoring duplicate`
      );
      return;
    }
    if (currentValue && currentValue !== mainTag) {
      this.warnings.push(
        `Already stored ${currentValue} as canonical form of ${key}, ignoring new value ${mainTag}`
      );
      return;
    }
    const cleanedKey = this.cleanLookupKey(key);
    // use canonical form of mainTag if one already exists
    this.lookupTable[cleanedKey] = this.lookup(mainTag) || mainTag.trim();
  }

  private cleanLookupKey(key: string): string {
    return key.trim().toLowerCase();
  }
}
