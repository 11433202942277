/* eslint-disable camelcase, spaced-comment, no-console */

////////////////////////
// Keyword filters
////////////////////////

type ParsedKeywordFilter = {
  includeKeywords: string[];
  excludeKeywords: string[];
};

export type KeywordFilter = {
  text: string;
  parsed: ParsedKeywordFilter;
};

export const keywordFilterFromTextInput = (text: string): KeywordFilter => {
  const includeKeywords: string[] = [];
  const excludeKeywords: string[] = [];

  text.split(' ').forEach((kw: string) => {
    const cleanedKw = kw.trim().toLocaleLowerCase();
    if (cleanedKw[0] === '-') {
      // exclude
      if (cleanedKw.length > 1) {
        excludeKeywords.push(cleanedKw.slice(1));
      }
    } else if (cleanedKw.length > 0) {
      includeKeywords.push(cleanedKw);
    }
  });

  return { text, parsed: { includeKeywords, excludeKeywords } };
};

// assumes keywords have already been cleaned (trimmed, lowercased,
// blanks removed)

export const isKeywordFilterMatch = (
  inputStringGroup: string[],
  keywordFilter: ParsedKeywordFilter
): boolean => {
  const cleanedInputGroup = inputStringGroup
    .map((s) => s.toLocaleLowerCase().trim())
    .filter((s) => s);
  const { includeKeywords, excludeKeywords } = keywordFilter;

  return (
    includeKeywords.every((kw) => cleanedInputGroup.some((s) => s && s.indexOf(kw) >= 0)) &&
    excludeKeywords.every((kw) => cleanedInputGroup.every((s) => !s || s.indexOf(kw) === -1))
  );
};

export type YesNoEitherFilter = 'yes' | 'no' | '';
export const isYesNoFilterMatch = (value: boolean, filter: YesNoEitherFilter): boolean =>
  filter === '' || (filter === 'yes' && value) || (filter === 'no' && !value);
