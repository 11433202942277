/* eslint-disable import/prefer-default-export */
export function debounce(f: (...args: any[]) => void, wait: number, immediate?: boolean) {
  let timeout: NodeJS.Timeout | null;
  return (...args: any[]) => {
    const later = () => {
      timeout = null;
      if (!immediate) f(...args);
    };
    const callNow = immediate && !timeout;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) f(...args);
  };
}
