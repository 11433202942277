import React from 'react';
import { Link } from 'react-router-dom';
import { Tweet } from 'react-twitter-widgets';

import styled from 'styled-components';

import { PATHS } from '@jan6evidence/routes';

import { PROJECT_CONFIG } from '@jan6evidence/settings';

import { PageHeaderAndNav } from './PageHeaderAndNav';

import Container from './shared/Container';
import { AppWrapper } from './table-styled-components';

const Error404Div = styled.div`
  width: 800px;
  padding: 30px;
  margin-left: auto;
  margin-right: auto;

  /* Fixes a safari-specific issue where the header gets squashed on longer pages
     See https://github.com/jan6evidence/static-website/pull/177 */
  min-height: -webkit-fill-available;

  * + h2 {
    margin-top: 2rem;
  }
`;

function Error404Page({ location }: { location: any }) {
  return (
    <AppWrapper>
      <PageHeaderAndNav projectConfig={PROJECT_CONFIG} />
      <Error404Div>
        <h2>404: Not found</h2>
        <p>{location.pathname}</p>
      </Error404Div>
    </AppWrapper>
  );
}

export default Error404Page;
