export { fbiLinksByFbiNumber } from './fbi_links';

import suspectData from './suspects.json';
import nonSuspectHashtagData from './non-suspect-hashtags.json';
import { CanonicalTags } from '@jan6evidence/canonical_tags';
import { Suspect, getSuspectsFromRawData } from '@jan6evidence/types';

export const suspects = getSuspectsFromRawData(suspectData);

// most callers will just want to use `canonicalSuspectTags`, but the suspect import script needs
// to call the getCanonicalSuspectTags function directly.
export const getCanonicalSuspectTags = (suspects: Array<Suspect>): CanonicalTags => {
  const tags = new CanonicalTags(
    suspects.flatMap((s) =>
      // ignore suspects without hashtags
      s.primaryHashtag ? [{ mainTag: s.primaryHashtag, alternateTags: s.alternateHashtags }] : []
    )
  );
  tags.logWarningsToConsole();
  return tags;
};

export const canonicalSuspectTags = getCanonicalSuspectTags(suspects);

export const nonSuspectHashtags = nonSuspectHashtagData;

export const getCanonicalNonsuspectHashtag = (
  str: string
): { topicName: string; topicType: string } | undefined => {
  for (let i = 0; i < nonSuspectHashtags.length; i++) {
    if (nonSuspectHashtags[i].topicName.toLowerCase() === str.toLowerCase().trim()) {
      return nonSuspectHashtags[i];
    }
  }
  return undefined;
};
